import axios from 'axios';
import api from 'config/api';
import _ from 'lodash';

let instance;
export const fetchClient = () => {
  if (instance) return instance;

  const defaultOptions = {
    baseURL: api.baseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
    validateStatus: () => true, // so that we can throw our own custom errors (below)
  };
  instance = axios.create(defaultOptions);

  // insert auth headers to all requests
  instance.interceptors.request.use(config => {
    let jwt = JSON.parse(localStorage.getItem('jwt'));
    if (jwt && !(config.headers && config.headers.authorization)) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers.authorization = 'jwt ' + jwt;
    }

    const urlParams = new URLSearchParams(window.location.search);
    let dt = urlParams.get('delegateToken') || JSON.parse(localStorage.getItem('delegateToken'));
    if (dt && !(config.headers && config.headers.dt)) {
      if (!config.headers) config.headers = {};

      config.headers.dt = dt;
    }

    return config;
  });

  instance.interceptors.response.use(async res => {
    const jwt = res.data.jwt || res.headers['x-jwt'];
    if (jwt) {
      const event = new CustomEvent('newJwt', { detail: { jwt } });
      window.dispatchEvent(event);
    }

    const dt = res.headers['x-dt'];
    if (dt) {
      const event = new CustomEvent('newDt', { detail: { dt } });
      window.dispatchEvent(event);
    }
    return res;
  });

  instance.interceptors.response.use(async res => {
    //await new Promise(r => setTimeout(r,500)); //uncomment to simulate delay
    if (!res) {
      return console.error('no res', res);
    }

    if (res.status === 401) {
      const event = new CustomEvent('unauthenticated', {
        detail: _.pick(res, ['status', 'config']),
      });
      window.dispatchEvent(event);
    }

    if (res.status < 200 || res.status >= 300) {
      throw res.data;
    } else {
      return res;
    }
  });

  return instance;
};
