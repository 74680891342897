import { Typography, useTheme } from '@mui/material';
import colors from '../../assets/theme/base/colors';
import TextField from '@mui/material/TextField';
import ui from '../../config/ui';
import BodyEditor from '../BodyEditor';
import TagEditor from './TheorySection/TagEditor';
import Button from '@mui/material/Button';
import CredentialModals from '../CredentialModals';
import { useContext, useEffect, useState } from 'react';
import AuthContext from '../../state/AuthContext';
import UiContext from '../../state/UiContext';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import { useIsTrusted } from '../../services/config';
import { usePrompt } from '../../hooks/react-router-dom-extension';
import ImagePicker from './TheorySection/ImagePicker';
import { useCheckThrottle } from '../../services/users';
import { useHasPrivilege } from '../../services/payments';
import { useCreateTheory, useStartUpdateTheory, useUpdateTheory } from '../../services/theories';
import { useImportSubs } from '../../services/subscription';

const TheoryEditor = ({ initialTitle, initialBody, theory, onClose }) => {
  const [title, setTitle] = useState(initialTitle || '');
  const [body, setBody] = useState(initialBody || '');
  const [chosenTags, setChosenTags] = useState([]); // list of tags to be added with this theory
  const [consensus, setConsensus] = useState();
  const [sd, setSd] = useState();
  const [importSlug, setImportSlug] = useState();
  const { completedSignup, isDelegated, isAuthenticated, isNamed, isAdmin } =
    useContext(AuthContext);
  const { toastSuccess, toastError } = useContext(UiContext);
  const [imageFile, setImageFile] = useState();
  const [trustedToUploadImages] = useIsTrusted('uploadImage');
  const createTheory = useCreateTheory();
  const updateTheory = useUpdateTheory();
  const startUpdateTheory = useStartUpdateTheory();
  const theme = useTheme();
  const [credentialsOpen, setCredentialsOpen] = useState(false);
  const [isTrustedToCreateTheory, trustLevelRequiredToCreateTheory] = useIsTrusted('createTheory');
  const throttleQuery = useCheckThrottle('theory-create', !theory);
  const throttle = throttleQuery.data?.data;
  const throttled = throttle?.atLimit;
  const importSubs = useImportSubs();
  const theoryPrivilege = useHasPrivilege('theory-create');
  const disabled =
    updateTheory.isLoading ||
    theory?.isLoading ||
    (!isTrustedToCreateTheory && !theoryPrivilege) ||
    (!theory && (throttleQuery.isLoading || throttled));

  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const prompt = theory ? 'Discard unsaved changes?' : 'Discard unsaved Inquiry?';
  usePrompt(prompt, unsavedChanges);

  useEffect(() => {
    if (theory) {
      startUpdateTheory.mutate(theory);
      !title && setTitle(theory?.title);
      !body && setBody(theory?.body);
      !chosenTags.length && setChosenTags(theory.tags);
      setUnsavedChanges(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theory]);

  function checkCredentialsAndSubmit() {
    if (!isAuthenticated || isDelegated || !completedSignup || !isNamed) setCredentialsOpen(true);
    else submit();
  }

  function submit() {
    createTheory.mutate(
      {
        title,
        body,
        tags: chosenTags.map(t => _.omit(t, 'slug')),
        image: imageFile,
      },
      {
        onSuccess: res => {
          toastSuccess(res.message);
          setTitle('');
          setBody('');
          setConsensus(undefined);
          setImageFile(undefined);
          setUnsavedChanges(false);
          setChosenTags([]);
          onClose && onClose();
        },
        onError: res => {
          toastError(res.message);
        },
      },
    );
  }

  const onClickImport = () =>
    importSubs.mutate(
      { id: theory.id, slug: importSlug },
      {
        onSuccess: d => {
          toastSuccess(d.message);
          setImportSlug(undefined);
        },
      },
    );

  function onCancelEdit() {
    if (unsavedChanges && !window.confirm(prompt)) return;

    if (theory?.status === 'editing') updateTheory.mutate({ id: theory.id }); // set status back to pending
    setTitle('');
    setBody('');
    setUnsavedChanges(false);
    setChosenTags([]);
    onClose && onClose();
  }

  function onUpdateTheory() {
    updateTheory.mutate(
      {
        id: theory.id,
        title,
        body,
        consensus,
        sd,
        tags: chosenTags.map(t => _.omit(t, 'slug')),
        image: imageFile,
      },
      {
        onSuccess: res => {
          toastSuccess(res.message);
          setTitle('');
          setBody('');
          setUnsavedChanges(false);
          setChosenTags([]);
          onClose && onClose();
        },
        onError: res => {
          toastError(res.message);
        },
      },
    );
  }
  return (
    <div className={'mx-1 md:mx-2 lg:mx-4'}>
      {!theory &&
        (!theoryPrivilege && !isTrustedToCreateTheory ? (
          <p className={'text-lg align-middle my-3 text-balance'}>
            Inquiry creation is available to members and those at Level{' '}
            {trustLevelRequiredToCreateTheory}+. You can{' '}
            <RouterLink to='/membership'>
              <span className='text-primary cursor-pointer'>Join Here</span>
            </RouterLink>{' '}
            to gain immediate access to this feature.
          </p>
        ) : !throttled ? (
          <p className={'text-lg align-middle my-3 text-balance'}></p>
        ) : (
          <p className='mx-6'>
            Thank you for your previous Inquiries. You have reached the limit for your membership
            plan. Please wait <span className='text-green-400'>{throttle?.timeUntil}</span> before
            creating a new one, or{' '}
            <RouterLink to='/membership'>
              <span className='text-primary cursor-pointer'>upgrade your plan here.</span>
            </RouterLink>
          </p>
        ))}
      <div className='mx-auto'>
        <div className='w-full'>
          <div>
            <div className='flex flex-col space-y-3'>
              {/*Title and Body*/}
              <div>
                <Typography
                  color={colors.text.unSelected + ' !important'}
                  marginBottom={2}
                  marginTop={3}
                  style={{ ...theme.typography.tableData }}
                >
                  Question
                </Typography>
                <div className='flex flex-row items-center'>
                  <TextField
                    disabled={disabled}
                    variant='outlined'
                    value={title}
                    fullWidth
                    inputProps={{ maxLength: ui.theoryTitleLength }}
                    onChange={e => {
                      setUnsavedChanges(true);
                      setTitle(e.target.value);
                    }}
                  />
                  {ui.theoryTitleLength - title.length < 10 && (
                    <p className={'text-unselected font-bold text-sm p-3'}>
                      {title.length - ui.theoryTitleLength}
                    </p>
                  )}
                </div>

                {trustedToUploadImages && !disabled && title && (
                  <div className='my-4'>
                    <ImagePicker
                      label={theory?.image ? 'Update Inquiry Image' : 'Add Inquiry Image'}
                      imageFile={imageFile}
                      setImageFile={img => {
                        setUnsavedChanges(true);
                        setImageFile(img);
                      }}
                    />
                  </div>
                )}

                {isAdmin && !!theory && (
                  <div>
                    <div className='flex flex-row space-x-3'>
                      <p className='font-bold'>Import Subs</p>
                      <input
                        className='px-2'
                        placeholder='slug'
                        value={importSlug}
                        onChange={e => setImportSlug(e.target.value)}
                      />
                      <button
                        className='p-2 text-sm border border-blue-900'
                        onClick={onClickImport}
                      >
                        From Inquiry
                      </button>
                    </div>

                    <div className='grid grid-cols-2 gap-4'>
                      <input
                        className='p-1 border'
                        placeholder='consensus'
                        value={consensus || theory?.consensus || ''}
                        onChange={e => {
                          setConsensus(e.target.value);
                          if (!sd) setSd(theory?.sd);
                        }}
                      />
                      <input
                        className='p-1 border'
                        placeholder='sd'
                        value={sd || theory?.sd || ''}
                        onChange={e => {
                          setSd(e.target.value);
                          if (!consensus) setConsensus(theory?.consensus);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div>
                {theoryPrivilege && (
                  <p className='text-unselected text-lg mb-2 ml-1'>
                    For help writing your Inquiry, see our{' '}
                    <a
                      className='text-primary'
                      target='_blank'
                      rel='noreferrer'
                      href='/help?topic=guidelines-for-creating-inquiries'
                    >
                      Inquiry guidelines
                    </a>
                  </p>
                )}
                <BodyEditor
                  body={body}
                  onChange={t => {
                    setUnsavedChanges(true);
                    setBody(t);
                  }}
                  disabled={disabled}
                />
              </div>

              <TagEditor
                chosenTags={chosenTags}
                setChosenTags={setChosenTags}
                disabled={disabled}
              />
            </div>
            <div className='mt-5 flex justify-center space-x-2'>
              <Button
                type='submit'
                variant='contained'
                onClick={theory ? onUpdateTheory : checkCredentialsAndSubmit}
                disabled={disabled}
              >
                {theory ? 'Save Changes' : 'Create Inquiry'}
              </Button>
              {theory && (
                <Button
                  variant='containedTertiary'
                  style={{ marginLeft: 10 }}
                  onClick={onCancelEdit}
                >
                  Cancel
                </Button>
              )}
            </div>
            {!theory && (
              <p className={'text-2xs text-slate-500 text-center mt-2'}>
                {throttle?.requestsRemaining}/{throttle?.capacity} remaining
              </p>
            )}
            <CredentialModals
              open={credentialsOpen}
              requireUsername={true}
              signinPrompt={
                isAuthenticated && !isDelegated
                  ? 'Please enter your email to create this inquiry'
                  : 'Please sign in to create this inquiry'
              }
              usernamePrompt={'Please choose a username to create this inquiry'}
              onSuccess={submit}
              onClose={() => setCredentialsOpen(false)}
              context='creating inquiry'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TheoryEditor;
