// @mui icons
import TwitterIcon from '@mui/icons-material/Twitter';

// Material Kit 2 PRO React examples
import CenteredFooter from './CenteredFooter';
import { Icon } from '@mui/material';

function Footer() {
  const routes = [
    { href: '/', name: 'Inquiries' },
    { href: '/help', name: 'Help' },
    { href: '/help?topic=about', name: 'About' },
    { href: '/contact', name: 'Contact Us' },
    { href: '/help?topic=privacy-policy', name: 'Privacy' },
  ];

  const socials = [
    // {
    //   icon: <LinkedInIcon fontSize="small" />,
    //   link: "",
    // },
    // {
    //   icon: <FacebookIcon fontSize="small" />,
    //   link: "",
    // },
    {
      tooltip: 'Follow us on Twitter (X)',
      icon: <TwitterIcon fontSize='small' />,
      link: 'https://twitter.com/evincer_org',
    },
    // {
    //   icon: <InstagramIcon fontSize="small" />,
    //   link: "",
    // },
    // {
    //   icon: <YouTubeIcon fontSize="small" />,
    //   link: "",
    // },
    {
      tooltip: 'Join our chatroom in Discord',
      icon: <Icon>discord</Icon>,
      link: 'https://discord.gg/9kR9h4cmGV',
    },
  ];

  return <CenteredFooter routes={routes} socials={socials} />;
}

export default Footer;
