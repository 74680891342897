const id = assoc =>
  assoc?.id || (typeof assoc === 'string' && assoc) || (assoc === null ? null : undefined);

export default id;

export const sameId = (assocA, assocB) => {
  const idA = id(assocA);
  const idB = id(assocB);
  return idA === idB && !!idA;
};

const protoHost = window.location.protocol + '//' + window.location.hostname;

export const evidencePath = evidence =>
  evidence &&
  (evidence.parent ? '/rebuttal/' : '/evidence/') +
    (evidence.status === 'published' ? evidence.slug : evidence.id);

export const evidenceLink = evidence => protoHost + evidencePath(evidence);

export const theoryPath = theory =>
  theory && '/inquiry/' + (theory.status === 'published' ? theory.slug : theory.id);

export const theoryLink = theory => protoHost + theoryPath(theory);

export const userPath = user => '/user/' + (user.username || user.id);

export const userLink = user => protoHost + userPath(user);
