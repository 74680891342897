import { useActivity } from '../../services/activity';
import ActivityList from '../../components/ActivityList';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { useState } from 'react';
import { flattenPages } from '../../util/query';

const AdminActivity = () => {
  const types = [
    'add-author',
    'confirm-authorship',
    'theory-publish',
    'theory-unpublish',
    'theory-republish',
    'theory-activate',
    'theory-edit',
    'evidence-activate',
    'evidence-draft',
    'evidence-publish',
    'evidence-unpublish',
    'evidence-republish',
    'evidence-edit',
    'cluster-add',
    'cluster-exclude',
    'theory-likelihood-log',
    'evidence-rank-log',
    'theory-rank-log',
    'user-invite',
    'user-signup',
    'user-trust-adjust',
    'comment-create',
    'comment-respond',
    'share',
  ];

  const [showTrust, setShowTrust] = useState(false);
  const activityQuery = useActivity({ types });
  const activity = flattenPages(activityQuery.data?.pages);

  return (
    <Container>
      <Button variant={showTrust ? 'primary' : ''} onClick={() => setShowTrust(!showTrust)}>
        Show 📜
      </Button>
      <ActivityList activities={activity} showUsername={true} showTrust={showTrust} />
      {activityQuery.hasNextPage && (
        <Button onClick={() => activityQuery.fetchNextPage()}>Load more</Button>
      )}
    </Container>
  );
};

export default AdminActivity;
