import { fetchClient } from 'services/api';

const client = fetchClient();

export const healthCheck = async () => {
  try {
    await client.get('/health');
    console.debug('Base API URL works');
  } catch (e) {
    console.error('Base API URL fails', e);
  }
};
