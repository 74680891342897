import { useCallback, useContext, useEffect, useState } from 'react';
import UiContext from 'state/UiContext';
import { useGetToken, useVerifyToken } from 'services/auth';
import { validateEmail } from 'services/validators';
import ValidationError from 'components/ValidationError';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Logo from 'assets/images/logo.png';
import Button from '@mui/material/Button';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import colors from 'assets/theme/base/colors';
import { useMe } from 'services/users';
import Stack from '@mui/material/Stack';
import AuthContext from 'state/AuthContext';
import Twitter from 'util/twitter';
import Reddit from 'util/reddit';

const useStyles = makeStyles({
  root: {
    boxShadow: '10px 7px 41px rgba(158, 165, 180, 0.1)',
    '& .emailAddress': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '22px',
      marginTop: '30px',
      paddingLeft: 2,
      color: colors.grey.main,
      backgroundColor: '#F2F2F2',
      width: '100%',
      height: '55px',
      border: '1px solid ' + colors.white.main,
      display: 'flex',
      alignSelf: 'center',
      alignItems: 'center',
      borderRadius: '4px',
    },
    '& a': {
      textDecoration: 'none',
      color: colors.brand.default,
      fontSize: '16px',
      fontWeight: 500,
    },
    '& p': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '24px',
      lineHeight: '160%',
      marginTop: 0,
      marginBottom: 0,
    },
    '& .MuiTextField-root': {
      width: '100%',
    },
    '& button': {
      marginTop: '12px',
      width: '100%',
    },
    '& .logo': {
      '& img': {
        width: '50px',
      },
      '& span': {
        color: '#114573',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '22.3276px',
        lineHeight: '27px',
        alignSelf: 'center',
        marginLeft: '5px',
      },
      marginBottom: '30px',
    },
  },
});

export default function SignInForm({
  newVisitor,
  compact,
  prompt,
  sx,
  redirect,
  context,
  onSuccess,
}) {
  const { tokenSentTo, setTokenSentTo, isDelegated } = useContext(AuthContext);
  const [signedIn, setSignedIn] = useState(false);
  const [email, setEmail] = useState(tokenSentTo || '');
  const [token, setToken] = useState('');
  const [promptToSwitchEmail, setPromptToSwitchEmail] = useState(false);
  const meQuery = useMe();
  const me = meQuery.data?.data;
  const [validationError, setValidationError] = useState('');
  const createToken = useGetToken();
  const verifyToken = useVerifyToken();
  const { lgPlus, toastSuccess, toastError } = useContext(UiContext);
  const [didYouMean, setDidYouMean] = useState('');
  const [useRedirect, setUseRedirect] = useState(false);

  const _prompt = didYouMean
    ? 'Did you mean ' + didYouMean + '?'
    : !tokenSentTo
    ? prompt || 'Please enter your email'
    : "We've emailed you a token. Please copy & paste it here.";

  const onSignIn = useCallback(() => {
    if (!signedIn) {
      if (newVisitor && me?.id) {
        Reddit.init(me.id);
        Reddit.track('SignUp');
      }
      toastSuccess((newVisitor ? 'Sign-up' : 'Sign-in') + ' successful!');
      redirect && setUseRedirect(true);
      setSignedIn(true);
      setTokenSentTo(false);
      onSuccess && onSuccess();
    }
  }, [newVisitor, me, toastSuccess, signedIn, setSignedIn, setTokenSentTo, redirect, onSuccess]);

  useEffect(() => {
    if (me?.completedSignup && !isDelegated && !verifyToken.isLoading && !verifyToken.isSuccess)
      onSignIn();
  }, [meQuery, verifyToken, onSignIn, me?.completedSignup, isDelegated]);

  function onChangeToken(e) {
    setToken(e.target.value.trim());
  }

  function onChangeEmail(e) {
    const value = e.target.value.trim();
    if (value && validationError) setValidationError(validateEmail(value));
    setEmail(value);
    setDidYouMean('');
  }

  function _createToken(email) {
    createToken.mutate(
      { email, context },
      {
        onSuccess: () => {
          if (newVisitor && me?.id) {
            Reddit.init(me.id);
            Reddit.track('Lead');
          }
          setTokenSentTo(email);
          Twitter.track(process.env.REACT_APP_TWITTER_SUBSCRIBE_ID);
          setDidYouMean('');
          toastSuccess('Token sent to ' + email);
        },
        onError: res => {
          toastError(res.message);
          setDidYouMean(res.data?.didYouMean);
        },
      },
    );
  }

  function onSubmitEmail() {
    if (!email) return;
    const error = validateEmail(email);
    setValidationError(error);
    if (error) {
      toastError(error);
      return;
    }
    _createToken(email);
  }

  function onClickYesImeantThat() {
    _createToken(didYouMean);
  }

  function onSubmitToken() {
    if (validationError) {
      toastError(validationError);
      return;
    }
    if (token) {
      verifyToken.mutate(
        { token: token },
        {
          onSuccess: onSignIn,
          onError: res => toastError(res.message),
        },
      );
    }
  }

  const classes = useStyles();
  if (useRedirect) return <Navigate to={redirect} />; // useNavigate wasn't working for some reason
  else
    return (
      <Box className={classes.root} sx={sx} alignSelf={'center'} width='100%'>
        {!compact && (
          <>
            <Box display='flex' className='logo'>
              <img src={Logo} alt='Logo' />
              <span>Evincer</span>
            </Box>
            <p data-cy='auth-card-title' style={{ color: '#9F9F9F' }}>
              {newVisitor ? 'Welcome to Evincer...' : 'Welcome again...'}
            </p>
            <p style={{ color: '#002534' }}>{_prompt}</p>
          </>
        )}

        {compact && !tokenSentTo && (
          <p style={{ fontSize: '14px', marginBottom: '8px' }}>{_prompt}</p>
        )}
        <Stack direction='row'>
          <div className='w-full relative'>
            <TextField
              name='email'
              type='email'
              disabled={!!tokenSentTo}
              autoFocus={lgPlus}
              placeholder={'Email' + (newVisitor ? ' (+500 📜)' : '')}
              value={email}
              onKeyUp={e => e.key === 'Enter' && onSubmitEmail()}
              onChange={onChangeEmail}
              sx={{ marginTop: compact ? '8px' : '30px' }}
            />
            {!!tokenSentTo && (
              <div
                className='absolute top-0 bottom-0 left-0 right-0'
                onClick={() => !!tokenSentTo && setPromptToSwitchEmail(true)}
              />
            )}
          </div>
          {promptToSwitchEmail && (
            <Button
              size='small'
              onClick={() => {
                setTokenSentTo(false);
                setPromptToSwitchEmail(false);
              }}
            >
              Change email?
            </Button>
          )}
        </Stack>
        {compact && !!tokenSentTo && (
          <p style={{ fontSize: '16px', marginTop: '16px' }}>{_prompt}</p>
        )}
        {!!tokenSentTo && (
          <TextField
            sx={{ marginTop: '8px' }}
            name='token'
            placeholder={'Token'}
            value={token}
            onKeyUp={e => e.key === 'Enter' && onSubmitToken()}
            onChange={onChangeToken}
          />
        )}
        <ValidationError data-cy='auth-validation-error' error={validationError} />

        {didYouMean ? (
          <Button
            variant='contained'
            size='medium'
            onClick={onClickYesImeantThat}
            disabled={createToken.isLoading}
          >
            Use {didYouMean}
          </Button>
        ) : (
          <Button
            data-cy='auth-btn'
            variant='contained'
            size='medium'
            onClick={!tokenSentTo ? onSubmitEmail : onSubmitToken}
            disabled={createToken.isLoading || verifyToken.isLoading}
          >
            {!tokenSentTo ? 'Submit' : !newVisitor ? 'Sign In' : 'Sign Up'}
          </Button>
        )}

        {!compact && !didYouMean && !tokenSentTo && (
          <Box display='flex' marginTop={2}>
            <Box fontSize={16} fontWeight={500} color='#52575E' marginRight={1}>
              {newVisitor ? 'Already have an account?' : 'No account? '}{' '}
            </Box>
            <RouterLink to={newVisitor ? '/sign-in' : '/sign-up'}>
              <span>{newVisitor ? 'Sign In' : 'Sign Up'}</span>
            </RouterLink>
          </Box>
        )}
      </Box>
    );
}
