import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import AttributionIcon from '@mui/icons-material/Attribution';
import NotesIcon from '@mui/icons-material/Notes';
import ShareIcon from '@mui/icons-material/Share';
import CompressIcon from '@mui/icons-material/Compress';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { EditIcon, FlameFitIcon, PaperPlusIcon, TrashIcon } from '../icons';
import { Tooltip } from '@mui/material';
import { useIsTrusted } from '../../services/config';
import { evidencePath, sameId } from '../../util/id';
import { useCallback, useContext, useEffect, useState } from 'react';
import AuthContext from '../../state/AuthContext';
import UiContext from '../../state/UiContext';
import Confirmation, { ConfirmationWithInput } from '../Confirmation';
import { useConfirm, useDeleteEvidence, usePublishEvidence } from '../../services/evidence';
import ClusterModal from './ClusterModal';
import { track } from '../../services/analytics';
import RankPanel from '../Rank/RankPanel';
import classnames from 'classnames';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AddAuthorModal from './AddAuthorModal';
import { useIsAbTest } from '../../services/ab-tests';
import { reportShare } from '../../services/users';
import UsernameModal from '../UsernameModal';
import { keys as evidenceKeys } from 'services/evidence';
import { useQueryClient } from 'react-query';

const PublishButton = ({ evidence, className, children }) => {
  const { isAdmin } = useContext(AuthContext);
  const publishEvidence = usePublishEvidence();
  const [showConfirm, setShowConfirm] = useState(false);
  const navigate = useNavigate();
  const { toastError } = useContext(UiContext);

  const invalidReason = !isAdmin ? 'Must be admin' : '';

  return (
    <>
      <Confirmation
        prompt={'Publish Evidence "' + evidence.title + '?"'}
        open={showConfirm}
        onClose={confirmed => {
          setShowConfirm(false);
          if (confirmed)
            publishEvidence.mutate(evidence, {
              onSuccess: res => res.data && navigate(evidencePath(res.data)),
              onError: res => toastError(res.message),
            });
        }}
      />

      <Tooltip title={invalidReason}>
        <button
          className={className}
          onClick={e => {
            e.stopPropagation();
            if (!invalidReason) {
              setShowConfirm(true);
            } else toastError(invalidReason);
          }}
        >
          {children}
        </button>
      </Tooltip>
    </>
  );
};

const UnpublishButton = ({ onConfirm, evidence, children, className }) => {
  const [openDlg, setOpenDlg] = useState(false);

  function onClick(e) {
    e.stopPropagation();
    setOpenDlg(true);
  }

  function onClose(reason) {
    setOpenDlg(false);
    if (reason) onConfirm(reason);
  }

  return (
    <>
      <ConfirmationWithInput
        prompt={'Withdraw "' + evidence.title + '?"'}
        open={openDlg}
        onClose={onClose}
        label='Reason'
        inputRequired
      />

      <button className={className} onClick={onClick}>
        {children}
      </button>
    </>
  );
};

const DeleteButton = ({ evidence, className }) => {
  const [openDlg, setOpenDlg] = useState(false);
  const { toastError } = useContext(UiContext);
  const deleteEvidence = useDeleteEvidence();

  function onClick(e) {
    e.stopPropagation();
    setOpenDlg(true);
  }

  function onClose(response) {
    setOpenDlg(false);
    if (response)
      deleteEvidence.mutate(evidence, {
        onError: res => toastError(res.message),
      });
  }

  return (
    <>
      <Confirmation
        prompt={'Delete Evidence "' + evidence.title + '?"'}
        open={openDlg}
        onClose={onClose}
      />

      <button className={className} onClick={onClick}>
        <img className='w-3 h-3' src={TrashIcon} alt='Trash Icon' /> <p>Delete</p>
      </button>
    </>
  );
};

const AddAuthorButton = ({ evidence, className, onClick }) => (
  <>
    <button className={className} onClick={onClick}>
      <img src={PaperPlusIcon} alt='add' className={'w-3 h-3'} />{' '}
      <p>Add Author ({evidence.authors?.length || 0})</p>
    </button>
  </>
);

const ConfirmButton = ({ evidence, className }) => {
  const { toastSuccess, toastError } = useContext(UiContext);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [showUsername, setShowUsername] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const confirmAuthorship = useConfirm();
  const testNoConfirmation = useIsAbTest('no-claim-confirmation', searchParams.get('author'));
  const queryClient = useQueryClient();

  function confirm() {
    confirmAuthorship.mutate(
      { evidence },
      {
        onSuccess: () => {
          toastSuccess('Authorship Confirmed');
          setShowSubscribe(true);
        },
        onError: data => toastError(data.message),
      },
    );
  }

  function onCloseConfirm(clickedYes) {
    if (clickedYes) confirm();

    setShowConfirm(false);
  }

  function onCloseSubscribe(clickedYes) {
    setShowSubscribe(false);
    if (clickedYes)
      confirmAuthorship.mutate(
        { evidence, subscribe: true },
        {
          onSuccess: () => {
            toastSuccess('Subscription Successful');
            setShowUsername(true);
          },
          onError: data => toastError(data.message),
        },
      );
    else setShowUsername(true);
  }

  function onCloseUsername() {
    queryClient.invalidateQueries(evidenceKeys.one(evidence.id));
    setShowUsername(false);
  }

  const onClick = useCallback(() => {
    track('click-confirm', { evidence });
    if (testNoConfirmation) {
      confirm();
    } else setShowConfirm(true);
  }, [evidence, testNoConfirmation, toastSuccess, setShowSubscribe, setShowConfirm]);

  useEffect(() => {
    if (searchParams.get('confirm-authorship')) {
      onClick();
      searchParams.delete('confirm-authorship');
      setSearchParams(searchParams);
    }
  }, [searchParams, setShowConfirm, setShowConfirm, onClick, setSearchParams]);

  return (
    <>
      <Confirmation
        open={showConfirm}
        prompt={"Confirm your authorship of this evidence's source?"}
        onClose={onCloseConfirm}
      ></Confirmation>

      <Confirmation
        open={showSubscribe}
        prompt={'Would you like weekly updates on this Inquiry?'}
        onClose={onCloseSubscribe}
      ></Confirmation>

      <UsernameModal
        open={showUsername}
        title={'Please choose a username'}
        onSuccess={onCloseUsername}
        onClose={onCloseUsername}
      />

      <button id='confirm-button' className={className} onClick={onClick}>
        <AttributionIcon /> <p>Confirm Authorship</p>
      </button>
    </>
  );
};

const Popup = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);
  const onClickToggleMenu = () => setShowMenu(!showMenu);

  return (
    <div className='relative'>
      {children?.some(c => c) && (
        <button
          onClick={onClickToggleMenu}
          className={'px-1.5 md:px-2 border rounded h-full bg-white hover:bg-gray-100'}
        >
          <MoreVertIcon />
        </button>
      )}
      {showMenu && (
        <div
          className='w-40 z-10 border rounded drop-shadow-md absolute right-0 mt-1'
          onClick={() => setShowMenu(false)}
        >
          {children}
        </div>
      )}
    </div>
  );
};

const EvidenceToolbar = ({
  evidence,
  theory,
  isOwner,
  isAmongAuthors,
  comments,
  rebutting,
  onClickEdit,
  onConfirmUnpublish,
  onClickCopy,
  onClickPaste,
  onClickRebuttal,
  onClickComments,
  onClickRevisionHistory,
}) => {
  const { me, isNamed, isAdmin } = useContext(AuthContext);
  const { clipboardType, toastSuccess } = useContext(UiContext);
  const [isTrustedToCopy] = useIsTrusted('copyPaste');
  const [isTrustedToEdit] = useIsTrusted('editEvidence');
  const [isTrustedToCluster] = useIsTrusted('clusterEvidence');
  const [clusterModalOpen, setClusterModalOpen] = useState(false);
  const [addAuthorModalOpen, setAddAuthorModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const showConfirmButton = isAmongAuthors && !isNamed;
  const testBigConfirmButton = useIsAbTest('big-claim-button', showConfirmButton);

  const unresolvedComments = comments?.some(
    c => (isOwner && !c.authorResolved) || (sameId(c.user, me) && !c.commenterResolved),
  );

  function onClickShare() {
    reportShare({ evidence: evidence.id }).then(() => {});

    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(
        window.location.origin +
          evidencePath(evidence) +
          (me?.username ? '?sharedBy=@' + me.username : ''),
      );
      toastSuccess('URL copied to clipboard');
    }
  }

  const rankTooltip = `How well does this ${
    evidence.parent ? 'Rebuttal' : 'Evidence'
  } support the "${evidence.for ? 'Yes' : 'No'}" side?`;

  const buttonClass =
    'px-2 md:px-3 border rounded flex flex-row space-x-1 md:space-x-2 items-center font-bold bg-white hover:bg-gray-100';
  const menuItemClass =
    'px-3 py-3 flex flex-row space-x-2 items-center bg-white w-full hover:bg-gray-100';

  return (
    <div className='flex flex-row h-6 md:h-8 items-stretch justify-between text-2xs md:text-xs text-unselected'>
      <div className='flex flex-row items-stretch md:space-x-2'>
        <div className='rounded bg-white'>
          <RankPanel
            place={evidence.place}
            subject={evidence}
            subjectKey='evidence'
            tooltip={rankTooltip}
          />
        </div>

        {comments && (
          <button
            onClick={onClickComments}
            className={classnames(
              'rounded px-1',
              unresolvedComments ? 'text-secondary' : 'text-unselected',
            )}
          >
            <NotesIcon /> {comments?.length} Comment{comments?.length !== 1 && 's'}
          </button>
        )}
      </div>
      <div className='flex flex-row items-stretch space-x-1 md:space-x-2'>
        {clipboardType === 'evidence' && !rebutting && evidence.status !== 'archived' && (
          <Tooltip title='Paste'>
            <button onClick={onClickPaste} className={buttonClass}>
              <ContentPasteIcon />
            </button>
          </Tooltip>
        )}

        {!['draft', 'archived'].includes(evidence.status) && (evidence.body || evidence.parent) && (
          <button onClick={onClickRebuttal} className={buttonClass}>
            <FlameFitIcon className='relative bottom-0.5 size-3' />
            <p>Rebut</p>
          </button>
        )}

        {showConfirmButton ? (
          <ConfirmButton
            evidence={evidence}
            className={
              searchParams.get('author') && testBigConfirmButton
                ? buttonClass
                    .split(' ')
                    .filter(
                      c =>
                        !['px', 'py', 'bg', 'hover:bg', 'font', 'text'].find(str =>
                          c.startsWith(str),
                        ),
                    )
                    .join(' ') + ' px-4 py-4 bg-primary text-sm md:text-base text-white font-medium'
                : buttonClass
            }
          />
        ) : (
          <button onClick={onClickShare} className={buttonClass}>
            <ShareIcon /> <p>Share</p>
          </button>
        )}

        <Popup>
          {evidence.status !== 'archived' && (isOwner || isTrustedToEdit || isAdmin) && (
            <Tooltip title='Edit'>
              <button onClick={onClickEdit} className={menuItemClass}>
                <img className='w-[10px] h-[10px]' src={EditIcon} alt='Edit Icon' />
                <p>Edit</p>
              </button>
            </Tooltip>
          )}

          {isAdmin && ['archived', 'active'].includes(evidence.status) && (
            <PublishButton evidence={evidence} className={menuItemClass}>
              <AppRegistrationIcon />
              <p>Publish</p>
            </PublishButton>
          )}

          {(isAdmin || isOwner) && ['active', 'published', 'draft'].includes(evidence.status) && (
            <UnpublishButton
              onConfirm={onConfirmUnpublish}
              evidence={evidence}
              className={menuItemClass}
            >
              <UnpublishedIcon />
              <p>Withdraw</p>
            </UnpublishButton>
          )}

          {isOwner && ['editing', 'pending'].includes(evidence.status) && (
            <DeleteButton evidence={evidence} className={menuItemClass} />
          )}

          {isTrustedToCopy && (
            <button className={menuItemClass} onClick={onClickCopy}>
              <ContentCopyIcon />
              <p>Copy</p>
            </button>
          )}

          {isTrustedToCluster && (
            <button className={menuItemClass} onClick={() => setClusterModalOpen(true)}>
              <CompressIcon />
              <p>Flag Duplicate</p>
            </button>
          )}

          {isAdmin && (
            <AddAuthorButton
              evidence={evidence}
              className={menuItemClass}
              onClick={() => setAddAuthorModalOpen(true)}
            />
          )}

          {evidence.editedBy && (
            <Tooltip title={'See Revision History'}>
              <button className={menuItemClass} onClick={onClickRevisionHistory}>
                <img className='w-[10px] h-[10px]' src={EditIcon} alt='Edit Icon' />
                <p>Revision History</p>
              </button>
            </Tooltip>
          )}
        </Popup>
      </div>

      <AddAuthorModal
        evidence={evidence}
        open={addAuthorModalOpen}
        onClose={() => setAddAuthorModalOpen(false)}
      />

      <ClusterModal
        open={clusterModalOpen}
        evidence={evidence}
        theory={theory}
        onClose={() => setClusterModalOpen(false)}
      />
    </div>
  );
};

export default EvidenceToolbar;
