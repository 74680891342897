import HeadTags from '../components/HeadTags';
import Heading from '../components/Banners/Heading';
import BannerImg from '../assets/images/evidence.png';
import { useAllEvidences } from '../services/evidence';
import { useEffect, useState } from 'react';
import { flattenPages } from '../util/query';
import classnames from 'classnames';
import { useIsTrusted } from '../services/config';
import { track } from '../services/analytics';
import EvidenceGrid from '../components/Cards/EvidenceGrid';
import { useSearchParams } from 'react-router-dom';
import { useInfiniteScroll } from '../util/dom';
import _ from 'lodash';
import ui from '../config/ui';

const TabButton = ({ id, label, active, onClick }) => {
  return (
    <button
      className={classnames('text-info text-sm p-4 border mb-3', {
        'bg-gray-300': id === active,
      })}
      onClick={() => {
        track('all-evidence-tab', { name: _.capitalize(id) });
        onClick(id);
      }}
    >
      {label}
    </button>
  );
};

const AllEvidence = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState(searchParams.get('tab') || 'published');
  const [authorless, setAuthorless] = useState(false);
  const [isTrustedToPromote] = useIsTrusted('promote');
  const [isTrustedToCopyPaste] = useIsTrusted('copyPaste');

  const evidencesQuery = useAllEvidences({
    perPage: 20,
    status: tab,
    parent: null,
    sortBy: tab === 'published' ? 'publishedAt' : tab === 'active' ? 'activatedAt' : 'createdAt',
    authorless,
  });
  const evidences = evidencesQuery.data ? flattenPages(evidencesQuery.data?.pages) : null;

  useInfiniteScroll(evidencesQuery);

  useEffect(() => {
    if (searchParams.get('tab')) setTab(searchParams.get('tab'));
    if (searchParams.get('authorless')) setAuthorless(true);
  }, [searchParams]);

  const _setTab = id => {
    setTab(id);
    searchParams.set('tab', id);

    setSearchParams(searchParams);
  };

  return (
    <div>
      <HeadTags title='Evidence' />
      <Heading
        text={
          tab === 'published'
            ? 'Top Evidence'
            : tab === 'active'
            ? 'New Evidence'
            : 'Evidence Drafts'
        }
        tooltip={
          tab === 'published'
            ? 'Top-10 with a Relevance section of ' +
              ui.evidenceFewChars +
              ' characters or more; emailed to subscribers'
            : tab === 'active'
            ? 'Evidence that has a written Relevance section but is not in the Top list'
            : 'Evidence that does not have a written Relevance section'
        }
        backgroundImg={BannerImg}
      />
      <div className='mx-4'>
        {isTrustedToPromote && (
          <div className='flex flex-row space-x-1 justify-end mt-8'>
            <TabButton id='published' label='Top' onClick={_setTab} active={tab} />
            <TabButton id='active' label='New' onClick={_setTab} active={tab} />
            {isTrustedToCopyPaste && (
              <TabButton id='draft' label='Drafts' onClick={_setTab} active={tab} />
            )}
          </div>
        )}
        <EvidenceGrid
          evidences={evidences}
          className='mt-4'
          isFetching={evidencesQuery.isFetching}
        />
      </div>
    </div>
  );
};

export default AllEvidence;
