import React, { useCallback, useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import UiContext from 'state/UiContext';
import { useDisableSubscription, useExplainSubscription } from 'services/subscription-settings';
import _ from 'lodash';
import { useGetTheory, useTheories } from '../services/theories';
import { useInfiniteScroll } from '../util/dom';
import { flattenPages } from '../util/query';
import TheoryGrid from '../components/Cards/TheoryGrid';
import { useUnsubscribeTheory } from '../services/subscription';
import Heading from '../components/Banners/Heading';
import BannerImg from '../assets/images/help.png';

const ExitSurvey = () => {
  const reasons = [
    "I'm not interested in these topics",
    'Poor writing quality',
    'Evidence seems biased',
    "I don't trust these sources",
    "I don't have time to read these",
  ];
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [otherReason, setOtherReason] = useState('');
  const explainSubscription = useExplainSubscription();
  const [searchParams] = useSearchParams();

  const handleChange = reason => () =>
    setSelectedReasons(
      selectedReasons.includes(reason)
        ? _.without(selectedReasons, reason)
        : [...selectedReasons, reason],
    );

  const isChecked = reason => selectedReasons.includes(reason);

  const submit = () => {
    explainSubscription.mutate({
      id: searchParams.get('subscriptionId'),
      reasons: [...selectedReasons, otherReason].join(','),
    });
  };

  if (explainSubscription.isSuccess)
    return (
      <p className={'text-center mt-10'}>
        Thank you for sharing your feedback. You have been unsubscribed.
      </p>
    );
  else
    return (
      <div className='flex flex-col items-center'>
        <div className={'flex flex-col space-y-4 text-base'}>
          <p className='text-xl my-8'>Would you like to share why you're unsubscribing?</p>
          {reasons.map(reason => (
            <div className='flex space-x-3'>
              <input
                type='checkbox'
                key={reason}
                checked={isChecked(reason)}
                onChange={handleChange(reason)}
                color='secondary'
              />
              <button onClick={handleChange(reason)}>{reason}</button>
            </div>
          ))}
          <div className='flex space-x-3'>
            <p>Other:</p>
            <input
              className='p-1 border border-unselected'
              onChange={e => setOtherReason(e.target.value)}
            />
          </div>
          <button
            onClick={submit}
            disabled={explainSubscription.isLoading}
            className='border rounded bg-primary text-white p-2 mt-5'
          >
            Submit
          </button>
        </div>
      </div>
    );
};

const SubSumUnsubscribe = () => {
  const { toastSuccess } = useContext(UiContext);
  const theoriesQuery = useTheories({ status: 'published' });
  useInfiniteScroll(theoriesQuery);
  const [searchParams] = useSearchParams();
  const theories = flattenPages(theoriesQuery.data?.pages);
  const theory = useGetTheory(searchParams.get('theoryId')).data?.data;
  const theoryUnsubscribe = useUnsubscribeTheory();
  const [unsubFromSubSum, setUnsubFromSubSum] = useState(null);
  const disableSubscription = useDisableSubscription();

  const unsubbed = theories.filter(t => !t.subscription || t.subscription.isActive);

  const onClickStopTheory = useCallback(() => {
    theoryUnsubscribe.mutate(searchParams.get('theoryId'), {
      onSuccess: () => {
        setUnsubFromSubSum(false);
        toastSuccess('You have been unsubscribed');
      },
    });
  }, [theoryUnsubscribe, searchParams, setUnsubFromSubSum, toastSuccess]);

  const onClickStopSubSum = useCallback(() => {
    disableSubscription.mutate({ idOrToken: searchParams.get('subscriptionId') });
    setUnsubFromSubSum(true);
  }, [disableSubscription, searchParams, setUnsubFromSubSum]);

  return (
    <div>
      <Heading text='Unsubscribe' backgroundImg={BannerImg} />

      {unsubFromSubSum === null && (
        <div className='m-4 space-y-10 flex flex-col items-center mt-10'>
          <p>What would you like to change?</p>
          {theory?.id && (
            <button
              onClick={onClickStopTheory}
              className={'bg-primary font-bold p-3 text-white rounded block'}
            >
              Unsubscribe from "{theory?.title}"
            </button>
          )}
          <button
            onClick={onClickStopSubSum}
            className={'p-3 rounded border text-unselected block'}
          >
            Unsubscribe from "Your Evincer Weekly"
          </button>
        </div>
      )}

      {(theoryUnsubscribe.isLoading || disableSubscription.isLoading) && (
        <p className={'mt-5 text-center'}>Processing...</p>
      )}

      {unsubFromSubSum === false && (
        <div className='m-4 pt-14 space-y-8'>
          {!!unsubbed.length && (
            <>
              <p className='text-xl font-bold text-unselected'>
                What would you like to know more about instead?
              </p>
              <TheoryGrid
                theories={unsubbed}
                subscribeButton={true}
                isFetching={theoriesQuery.isFetching}
              />
            </>
          )}
        </div>
      )}

      {unsubFromSubSum === true && <ExitSurvey />}
    </div>
  );
};

export default SubSumUnsubscribe;
