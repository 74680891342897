import { fetchClient } from 'services/api';
import { useMutation } from 'react-query';
const apiClient = fetchClient();

const sendContactMessage = async message => {
  const res = await apiClient.post('contact-message', { message });

  return res.data;
};

export const useSendContactMessage = () => {
  return useMutation(message => sendContactMessage(message));
};
