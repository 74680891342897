import React, { useContext, useEffect, useState } from 'react';
import { Icon, Tooltip } from '@mui/material';

import { subscribe, unsubscribe } from 'services/subscription';
import UiContext from 'state/UiContext';

import AuthContext from '../state/AuthContext';
import SignInModal from './SignIn/SignInModal';
import { track } from '../services/analytics';
import classnames from 'classnames';

const SubscriptionButton = ({ subscription, dataKey, dataId, path, disabled, tooltip }) => {
  const { toastSuccess, toastError } = useContext(UiContext);
  const [subscriptionData, setSubscriptionData] = useState(subscription);
  const { completedSignup } = useContext(AuthContext);
  const [showSigninModal, setShowSigninModal] = useState(false);

  const isActive = subscriptionData?.isActive;

  useEffect(() => {
    setSubscriptionData(subscription);
  }, [subscription]);

  const _subscribe = async () => {
    try {
      const res = await subscribe({ path, dataKey, id: dataId });
      toastSuccess(res.message);
      setSubscriptionData(res.data);
    } catch (e) {
      toastError(e.message);
    }
  };

  const handleSubscribe = async () => {
    track('click-subscribe', { to: dataKey, id: dataId });
    if (completedSignup) await _subscribe();
    else setShowSigninModal(true);
  };

  const onSignInSuccess = async () => {
    await _subscribe();
    setShowSigninModal(false);
  };

  const handleUnsubscribe = async () => {
    track('click-unsubscribe', { to: dataKey, id: dataId });
    try {
      await unsubscribe({ path, id: dataId });
      toastSuccess('Unsubscribed successfully');
      setSubscriptionData('');
    } catch (e) {
      toastError(e.message);
    }
  };

  return (
    <Tooltip title={tooltip || ''}>
      <div className='fixed bottom-5 right-5 z-50'>
        <button
          disabled={disabled}
          type='submit'
          className={classnames(
            'font-medium rounded-lg text-sm px-5 py-2.5 text-center',
            { 'bg-gray-200': isActive },
            {
              'text-white bg-gradient-to-r from-primary via-blue-600 to-blue-700 hover:bg-gradient-to-br ring-2 ring-blue-300':
                !isActive,
            },
          )}
          onClick={isActive ? handleUnsubscribe : handleSubscribe}
        >
          <Icon>notifications</Icon>&nbsp; {isActive ? 'Subscribed' : 'Subscribe'}
        </button>
        <SignInModal
          open={showSigninModal}
          title='Enter your email to subscribe'
          onSuccess={onSignInSuccess}
          onClose={() => setShowSigninModal(false)}
          context='subscription button'
        />
      </div>
    </Tooltip>
  );
};

export default SubscriptionButton;
