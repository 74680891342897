import { createContext, useCallback, useEffect, useState } from 'react';
import Pusher from 'pusher-js';

import config from 'config/pusher';
import api from 'config/api';
import { useJwt } from '../services/auth';

const PushNotificationContext = createContext({});

export const PushNotificationProvider = ({ children }) => {
  const [pusher, setPusher] = useState();
  const [jwt] = useJwt();

  useEffect(() => {
    if (!jwt) {
      setPusher();
      return;
    }

    const auth = {
      endpoint: `${api.baseUrl}/auth/pusher`,
      headers: {
        authorization: `jwt ${jwt}`,
      },
    };

    const _pusher = new Pusher(config.key, {
      cluster: config.cluster,
      forceTLS: true,
      userAuthentication: auth,
      channelAuthorization: auth,
    });

    setPusher(_pusher);
  }, [jwt, setPusher]);

  const subscribe = useCallback(
    name => {
      if (!pusher) return;

      return pusher.subscribe(name);
    },
    [pusher],
  );

  const unsubscribe = useCallback(
    name => {
      if (!pusher) return;

      pusher.unsubscribe(name);
    },
    [pusher],
  );

  return (
    <PushNotificationContext.Provider value={{ subscribe, unsubscribe }}>
      {children}
    </PushNotificationContext.Provider>
  );
};

export default PushNotificationContext;
