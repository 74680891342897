import { useTheories } from '../services/theories';
import TheoryGrid from '../components/Cards/TheoryGrid';
import VideoEmbed from '../components/elements/VideoEmbed';
import useLocalStorageState from 'use-local-storage-state';
import { useInfiniteScroll } from '../util/dom';
import { flattenPages } from '../util/query';
import NewInquiryButton from '../components/NewInquiryButton';
import classnames from 'classnames';
import { useContext, useState } from 'react';
import SvgContainer from '../components/wrappers/SvgContainer';
import { default as Inquiry } from 'assets/images/Home/hero.svg';
import UiContext from '../state/UiContext';
import AuthContext from '../state/AuthContext';
import { useMe } from '../services/users';
import StructuredDataOrg from '../components/StructuredData/StructuredDataOrg';

const Splash = ({ onClose }) => {
  const [showVideo, setShowVideo] = useState(false);
  const { isXs } = useContext(UiContext);
  const { me } = useContext(AuthContext);

  return (
    <div>
      <div
        className={classnames(
          'relative border rounded-2xl mt-12 mb-8 px-4 sm:px-11 py-5',
          'flex flex-row justify-between',
          'bg-gradient-to-r from-[#F1642E] to-[#FAAA3E]',
        )}
      >
        <div className={'text-white py-3 sm:py-5 md:py-10 sm:pr-5'}>
          <p className='text-xl sm:text-2xl md:text-3xl font-bold text-pretty'>
            Where dissent and consensus meet as equals
          </p>
          <p className='text-xs sm:text-sm md:text-base mt-4 max-w-lg text-pretty'>
            Cited evidence on <b>both</b> sides of controversial questions
          </p>

          {showVideo ? (
            <div className='w-full shrink-0 mt-4'>
              <VideoEmbed link={'https://www.youtube.com/watch?v=awn7bt11hhk'} />
            </div>
          ) : (
            <button
              className={
                'mt-8 text-sm text-black rounded-full bg-orange-100 border-orange-200 p-2.5'
              }
              onClick={() => setShowVideo(true)}
            >
              Watch Video
            </button>
          )}
        </div>

        {(!showVideo || !isXs) && (
          <div className={'ml-3 sm:ml-5 md:shrink-0'}>
            <SvgContainer src={Inquiry} />
          </div>
        )}

        {me?.trustLevel >= 5 && (
          <button
            className='text-orange-100 absolute top-2 right-2 text-base md:text-xl font-bold'
            onClick={onClose}
          >
            x&nbsp;&nbsp;
          </button>
        )}
      </div>
      <div className='h-px bg-gradient-to-r from-white to-white via-[#CD9740] max-w-3xl mx-auto' />
    </div>
  );
};

const HomeHeading = ({ children, className, showButton }) => (
  <div className='flex justify-between items-end md:mb-4'>
    <p className={'font-bold text-xl md:text-3xl ' + className}>{children}</p>
    {showButton && (
      <div className='flex'>
        <NewInquiryButton />
      </div>
    )}
  </div>
);

export default function Home() {
  const theoriesQuery = useTheories({});
  useInfiniteScroll(theoriesQuery);
  const [splashClosed, setClosed] = useLocalStorageState('splash-closed', { defaultValue: false });
  const me = useMe().data?.data;
  const showButton = me?.trustLevel >= 1;

  const theories = theoriesQuery.isLoading
    ? [null, null, null, null, null, null, null, null]
    : flattenPages(theoriesQuery.data?.pages);

  const featured = theories.filter(t => t?.status === 'published');
  const others = theories.filter(t => t?.status !== 'published');

  return (
    <div className='mx-4 flex flex-col mb-10'>
      <StructuredDataOrg />

      {!splashClosed && <Splash onClose={() => setClosed(true)} />}

      {!theoriesQuery.isLoading && !!featured?.length && (
        <HomeHeading className='mt-5 md:mt-8' showButton={showButton}>
          Featured Inquiries
        </HomeHeading>
      )}
      <TheoryGrid
        theories={featured}
        isFetching={!others.length && theoriesQuery.isFetching}
        className='mt-4 mb-10'
      />

      {!theoriesQuery.isLoading && !!others?.length && (
        <HomeHeading className='mt-10' showButton={showButton}>
          Newest Inquiries
        </HomeHeading>
      )}
      <TheoryGrid
        theories={others}
        isFetching={!!others.length && theoriesQuery.isFetching}
        className='mt-4'
      />
    </div>
  );
}
