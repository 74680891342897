import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import { CardContent } from '@mui/material';
import { RightArrow } from '../../components/icons';

import Heading from '../../components/Banners/Heading';
import Body from '../../components/Body';
import HeadTags from '../../components/HeadTags';

import help from './Help.json';
import privacypolicy from './privacypolicy.json';
import termsofuse from './termsofuse.json';
import BannerImg from '../../assets/images/help.png';
import { track } from '../../services/analytics';
import classnames from 'classnames';
import StructuredDataOrg from '../../components/StructuredData/StructuredDataOrg';

const compiled = [...help, privacypolicy, termsofuse];

const topicMap = compiled.reduce((prev, curr) => {
  const slug = Object.keys(curr)[0];
  return { ...prev, [slug]: curr[slug] };
}, {});

const Help = () => {
  const [params, setParams] = useSearchParams();
  const [tab, setTab] = useState(params.get('topic'));
  const navigate = useNavigate();

  useEffect(() => {
    if (tab && !params.get('topic')) setTab(null);
    if (params.get('topic') && params.get('topic') !== tab) {
      setTab(params.get('topic'));
      window.scrollTo({ top: 0 });
    }
  }, [tab, params]);

  function onClickTopic(slug) {
    return () => {
      setParams('?topic=' + slug);
      setTab(slug);
    };
  }

  const slugs = compiled.map(section => Object.keys(section)[0]);

  const { topic, body } = useMemo(() => {
    if (tab) track('help-topic', { tab });
    const topic = topicMap[tab];
    const body = topic ? topic.slice(1).join('\n\n') : '';

    // if body is just a link, navigate to it
    const match = body.match(/^\((\/[^)]*)\)$/);
    if (match) navigate(match[1], { replace: true });

    return { topic, body };
  }, [tab, navigate]);

  return (
    <>
      <HeadTags title='Help' />

      {['about', 'privacy-policy', 'terms-of-use'].includes(tab) && <StructuredDataOrg />}

      <Heading text='Help Page' backgroundImg={BannerImg} />

      <div className='mt-3 flex space-x-3 md:space-x-7 mx-4'>
        <div
          className={classnames(
            !!tab
              ? 'flex flex-col space-y-4 md:space-y-5 w-1/4 md:w-1/3 shrink-0'
              : 'grid gap-3 grid-cols-2 sm:grid-cols-3 md:grid-cols-4',
          )}
        >
          {slugs.map(slug => (
            <button
              key={slug}
              className={classnames(
                'p-2 sm:p-4 flex justify-between items-center space-x-1 text-xs sm:text-sm md:text-base border rounded border-gray-200',
                tab ? 'text-left w-full' : 'md:p-6 text-left',
                tab === slug ? 'font-normal bg-[#FAF5EC]' : 'font-bold cursor-pointer',
              )}
              onClick={onClickTopic(slug)}
            >
              <p>{topicMap[slug][0]}</p>
              {!!tab && <RightArrow className='hidden sm:inline size-4 shrink-0' />}
            </button>
          ))}
        </div>

        {tab && topic && (
          <div>
            <p className='text-lg font-bold mb-5 text-center'>{topic[0]}</p>
            <Card>
              <CardContent>
                <div className='text-xs sm:text-sm md:text-base'>
                  <Body body={body} />
                </div>
              </CardContent>
            </Card>
          </div>
        )}
      </div>
    </>
  );
};

export default Help;
