/**
 All of the routes for Evincer
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Navbar.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `name` key is used for the name of the route on the Navbar.
 2. The `icon` key is used for the icon of the route on the Navbar.
 3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 4. The `route` key is used to store the route location which is used for the react router.
 5. The `href` key is used to store the external links location.
 7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
 8. The `description` key is used to define the description of
 a route under its name.
 9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
 you can set the columns amount based on this key.
 10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
 */

import AdminTags from 'pages/Admin/AdminTags';
import CreateTheory from '../pages/CreateTheory';
import AdminNewTheories from '../pages/Admin/AdminNewTheories';
import { Route } from 'react-router-dom';
import Theory from '../pages/Theory';
import Membership from '../pages/Membership';
import Container from '@mui/material/Container';
import UserProfile from '../pages/UserProfile';
import Evidence from '../pages/Evidence';
import Home from '../pages/Home';
import Theories from '../pages/Theories';
import SignUpWithLink from '../pages/SignUpWithLink';
import SignIn from 'pages/SignIn';
import SignUp from 'pages/SignUp';
import SubscriptionSettings from 'pages/SubscriptionSettings';
import Help from '../pages/Help/Help';
import Messages from 'pages/Messages';
import StyleGuide from 'pages/StyleGuide/index';
import { NotificationIcon, SearchIcon, HelpIcon } from 'components/icons';
import Search from '../pages/Search';
import AdminNewsletter from '../pages/Admin/AdminNewsletter';
import Invite from '../pages/Invite';
import AdminUsers from '../pages/Admin/AdminUsers';
import AdminActivity from '../pages/Admin/AdminActivity';
import Comments from '../pages/Comments';
import AdminMetrics from '../pages/Admin/AdminMetrics';
import AllEvidence from '../pages/AllEvidence';
import AdminSources from '../pages/Admin/AdminSources';
import AdminAbTests from '../pages/Admin/AdminAbTests';
import AdminEmail from '../pages/Admin/AdminEmail';
import AdminTheories from '../pages/Admin/AdminTheories';
import AdminUser from '../pages/Admin/AdminUser';
import Quiz from '../pages/Quiz';
import QuizResult from '../pages/QuizResult';
import QuizStats from '../pages/QuizStats';
import Alignment from '../pages/Alignment';
import SubSumUnsubscribe from '../pages/SubSumUnsubscribe';
import Contact from '../pages/Contact';

const staticMenu = [
  {
    name: 'Evidence',
    route: 'evidence',
    visibleTo: 'TL6',
  },
  {
    name: 'Admin',
    hidden: true,
    visibleTo: 'admin',
    collapse: [
      {
        name: 'tags',
        description: 'Create new tags',
        route: 'admin/tags',
      },
      {
        name: 'New Inquiries',
        description: 'Publish New Inquiries',
        route: 'admin/new-inquiries',
      },
      {
        name: 'Published Inquiries',
        route: 'admin/inquiries',
      },
      {
        name: 'Sources',
        route: 'admin/sources',
      },
      {
        name: 'newsletter',
        route: 'admin/newsletter',
      },
      {
        name: 'users',
        route: 'admin/users',
      },
      {
        name: 'activity',
        route: 'admin/activity',
      },
      {
        name: 'metrics',
        route: 'admin/metrics',
      },
      {
        name: 'AB Tests',
        route: 'admin/abtests',
      },
      {
        name: 'Email',
        route: 'admin/email',
      },
    ],
  },

  {
    name: 'Search',
    icon: SearchIcon,
  },
  { name: 'Help', icon: HelpIcon, route: '/help' },
  {
    name: 'Notifications',
    icon: NotificationIcon,
    hidden: true,
    visibleTo: 'authenticated',
    collapse: [],
  },

  {
    name: 'Account',
    collapse: [
      {
        name: 'Sign In',
        icon: 'login',
        route: '/sign-in',
        visibleTo: '!signedin',
      },
      {
        name: 'Sign Up',
        route: '/sign-up',
        icon: 'logout',
        visibleTo: '!signedup',
      },

      {
        name: 'View Profile',
        icon: 'perm_identity',
        route: '/user',
      },
      {
        name: 'Membership',
        visibleTo: 'signedup',
        icon: 'key',
        route: '/membership',
      },
      {
        name: 'Comments',
        id: 'comments',
        visibleTo: 'signedup',
        icon: 'notes',
        route: '/comments',
      },
      {
        name: 'Messages',
        id: 'messages',
        icon: 'mail_outline',
        visibleTo: 'signedin',
        route: '/messages',
      },
      {
        name: 'Invite',
        icon: 'co_present',
        visibleTo: 'invite',
        route: '/invite',
      },
      {
        name: 'Sign Out',
        visibleTo: 'signedin',
        icon: 'logout',
      },
    ],
  },
];
export default staticMenu;

export const isVisible = ({
  item,
  isGuest,
  isAuthenticated,
  isDelegated,
  completedSignup,
  isAdmin,
  trustLevel,
  trustedActions,
}) => {
  if (!item.visibleTo) return true;

  const boolMap = {
    guest: isGuest,
    authenticated: isAuthenticated, // remember that completedSignup implies isAuthenticated
    signedin: !isDelegated && completedSignup,
    signedup: completedSignup,
    completedSignup,
    admin: isAdmin,
    ...trustedActions.reduce((map, action) => ({ ...map, [action]: true }), {}),
  };
  for (let i = 1; i <= 20; i++) boolMap['TL' + i] = trustLevel >= i;
  return Object.keys(boolMap).some(
    setting =>
      (item.visibleTo === setting && boolMap[setting]) ||
      (item.visibleTo === '!' + setting && !boolMap[setting]),
  );
};

export const getRoutes = () => {
  const routes = [
    { path: '/', element: <Home />, exact: true },
    { path: '/admin/tags', element: <AdminTags /> },
    { path: '/admin/new-inquiries', element: <AdminNewTheories /> },
    { path: '/admin/inquiries', element: <AdminTheories /> },
    { path: '/admin/sources', element: <AdminSources /> },
    { path: '/admin/newsletter', element: <AdminNewsletter /> },
    { path: '/admin/user/:username', element: <AdminUser /> },
    { path: '/admin/users', element: <AdminUsers /> },
    { path: '/admin/activity', element: <AdminActivity /> },
    { path: '/admin/metrics', element: <AdminMetrics /> },
    { path: '/admin/abtests', element: <AdminAbTests /> },
    { path: '/admin/email', element: <AdminEmail /> },
    { path: '/comments/', element: <Comments /> },
    { path: '/quiz/', element: <Quiz /> },
    { path: '/quiz-result/', element: <QuizResult /> },
    { path: '/quiz-stats/', element: <QuizStats /> },
    { path: '/evidence/', element: <AllEvidence /> },
    { path: '/evidence/:slugOrId', element: <Evidence /> },
    { path: '/rebuttal/:slugOrId', element: <Evidence /> },
    { path: '/help', element: <Help /> },
    { path: '/proposal/:slug', element: <Theory /> },
    { path: '/inquiries/new', element: <CreateTheory /> },
    { path: '/messages', element: <Messages /> },
    { path: '/messages/:username', element: <Messages /> },
    { path: '/subscription-settings', element: <SubscriptionSettings /> },
    { path: '/inquiries', element: <Theories /> },
    { path: '/inquiries/:slug', element: <Theories /> },
    { path: '/inquiry/:slugOrId', element: <Theory /> },
    { path: '/user', element: <UserProfile /> },
    { path: '/user/:username', element: <UserProfile /> },
    { path: '/alignment/:username', element: <Alignment /> },
    { path: '/compare/:username', element: <Alignment /> },
    { path: '/style-guide', element: <StyleGuide /> },
    { path: '/sign-up/:token', element: <SignUpWithLink /> },
    { path: '/sign-in/:token', element: <SignUpWithLink /> }, // deprecated
    { path: '/sign-in', element: <SignIn /> },
    { path: '/sign-up', element: <SignUp /> },
    { path: '/search', element: <Search /> },
    { path: '/invite', element: <Invite /> },
    { path: '/membership', element: <Membership /> },
    { path: '/unsubscribe', element: <SubSumUnsubscribe /> },
    { path: '/contact', element: <Contact /> },

    {
      path: '*',
      exact: true,
      element: <Container>There's nothing here!</Container>,
    },
  ];
  return routes.map(r => (
    <Route exact={!!r.exact} path={r.path} element={r.element} key={r.path} />
  ));
};
