import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useMe } from '../services/users';
import { useEnroll } from '../services/ab-tests';
import { useQueryClient } from 'react-query';
import UiContext from './UiContext';
import { useDelegateToken, useIsAuthenticated, useIsDelegated, useJwt } from '../services/auth';

const AuthContext = createContext({});

export const AuthContextProvider = ({ children }) => {
  const [, setJwt] = useJwt();
  const [, setDelegateToken] = useDelegateToken();
  const [isAuthenticated, setIsAuthenticated] = useIsAuthenticated();
  const [tokenSentTo, setTokenSentTo] = useState(false);
  const [naming, setNaming] = useState(false);
  const warnedAboutSession = useRef(false);
  const meQuery = useMe();
  const me = meQuery.data?.data;
  const prevMe = useRef(me);
  const [isGuest, setIsGuest] = useState(true); // no valid jwt (a temporary condition unless signed out)
  const isDelegated = useIsDelegated();
  const [completedSignup, setCompletedSignup] = useState(false); // do we have a verified email?
  const [isNamed, setIsNamed] = useState(false); // do we have a valid jwt, email, and a username?
  const [isAdmin, setIsAdmin] = useState(false); // are we an admin?
  const [abTestEnrollments, setAbTestEnrollments] = useState([]);
  const enroll = useEnroll();
  const queryClient = useQueryClient();
  const { toastWarning } = useContext(UiContext);

  useEffect(() => {
    setIsAuthenticated(meQuery.isSuccess);
  }, [meQuery.isSuccess, setIsAuthenticated]);

  useEffect(() => {
    const handleUnauthorized = e => {
      const res = e.detail;
      setIsAuthenticated(false);
      if (
        !res.config?.headers?.dt &&
        res.config?.headers?.authorization
        //&&typeof res.data === 'object' // why?
      ) {
        setJwt('');
      }

      if (!warnedAboutSession.current) {
        toastWarning('Your session/link has expired.  Please log in to continue.');
        warnedAboutSession.current = true;
      }
    };
    window.addEventListener('unauthenticated', handleUnauthorized);

    const handleNewJwt = e => {
      setJwt(e.detail.jwt);
    };
    window.addEventListener('newJwt', handleNewJwt);

    const handleNewDt = e => setDelegateToken(e.detail.dt);
    window.addEventListener('newDt', handleNewDt);

    return () => {
      window.removeEventListener('unauthenticated', handleUnauthorized);
      window.removeEventListener('x-jwt', handleNewJwt);
      window.removeEventListener('x-dt', handleNewDt);
    };
  }, [setIsAuthenticated]);

  useEffect(() => {
    if (me?.id && prevMe.current?.id && me.id !== prevMe.current.id) {
      console.log('authcontext invalidatequeries');
      queryClient.invalidateQueries({ refetchType: 'none' }); // none prevents losing body editors
    }
    prevMe.current = me;
  }, [me, prevMe, queryClient]);

  const abEnroll = useCallback(
    slug =>
      setAbTestEnrollments(enrollments =>
        !enrollments.some(s => s === slug) ? [...enrollments, slug] : enrollments,
      ),
    [setAbTestEnrollments],
  );

  useEffect(() => {
    if (me)
      abTestEnrollments.forEach(
        slug =>
          !me.abTests?.includes(slug) && !me.abControls?.includes(slug) && enroll.mutate(slug),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, abTestEnrollments]);

  useEffect(() => {
    if (meQuery.isFetching) return;

    // todo why are these states and not just me's data?  maybe because we need to re-render dependents?
    // todo or why not have these as use______ hooks in auth.js?
    setIsGuest(!me?.id);
    setIsNamed(!!me?.username);
    setCompletedSignup(!!me?.completedSignup);
    setIsAdmin(me?.roles?.includes('admin'));
    setAbTestEnrollments([...(me?.abTests || []), ...(me?.abControls || [])]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  return (
    // prettier-ignore
    <AuthContext.Provider
      value={{
        tokenSentTo, setTokenSentTo,
        naming, setNaming,
        me, isGuest, completedSignup, isDelegated, isNamed, isAdmin,
        isAuthenticated,  // purely for convenience
        abEnroll,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
