import Box from '@mui/material/Box';
import { PhotoIcon } from './icons';
import { v1 as uuid } from 'uuid';
import { useUpdateMe } from '../services/users';
import { useContext } from 'react';
import UiContext from '../state/UiContext';
import { useIsTrusted } from '../services/config';
import { Tooltip } from '@mui/material';
import classnames from 'classnames';
import AuthContext from '../state/AuthContext';

const ImageUploadButton = ({ field, show, onSuccess }) => {
  const id = uuid();
  const { isDelegated } = useContext(AuthContext);
  const updateMe = useUpdateMe();
  const { toastError } = useContext(UiContext);
  const [isTrustedToUploadImage, trustLevelRequiredToUploadImage] = useIsTrusted('uploadImage');

  function onClick() {
    if (isTrustedToUploadImage && !isDelegated) document.getElementById(id).click();
  }

  function onChange(e) {
    updateMe.mutate(
      { [field]: e.target.files[0] },
      { onSuccess, onError: e => toastError(e.message) },
    );
  }

  return (
    <div
      className={classnames('w-full h-full hover:opacity-100', {
        'opacity-0': !show,
      })}
    >
      <Box className='flex w-full h-full items-center justify-center'>
        <input
          id={id}
          accept='image/*'
          className='imageInput'
          type='file'
          hidden
          onChange={onChange}
        />
        <Tooltip
          title={
            isDelegated
              ? 'Please log in to update your profile image'
              : isTrustedToUploadImage
              ? ''
              : 'Uploading images requires Level ' + trustLevelRequiredToUploadImage
          }
        >
          <img
            src={PhotoIcon}
            className='h-1/2 cursor-pointer'
            style={{ aspectRatio: '1/1' }}
            alt='Camera Icon'
            onClick={onClick}
          />
        </Tooltip>
      </Box>
    </div>
  );
};

export default ImageUploadButton;
