import React from 'react';
import { useAdminTheories } from '../../services/theories';
import { flattenPages } from '../../util/query';
import { useInfiniteScroll } from '../../util/dom';

function AdminTheories() {
  const theoriesQuery = useAdminTheories({ status: 'published' });
  useInfiniteScroll(theoriesQuery);
  const theories = flattenPages(theoriesQuery.data?.pages);

  const tenths = n => Math.round(n * 10) / 10;
  const hundredths = n => Math.round(n * 100) / 100;
  const percent = n => Math.round(n * 1000) / 10;

  return (
    <div className='grid grid-cols-11 gap-2 text-2xs'>
      <p className='col-span-2'>Title:</p>
      <p>Pairs:</p>
      <p>Reads</p>
      <p>Trust</p>
      <p>Subs:</p>
      <p>dDlvrd:</p>
      <p>dOpenRate:</p>
      <p>dClickRate:</p>
      <p>dDelta:</p>
      <p>Unsubs:</p>

      {theories.map(t => (
        <React.Fragment key={t.id}>
          <a className='col-span-2' href={'/inquiry/' + t.slug}>
            {t.title}
          </a>
          <p>
            {t.publishedTopTen?.no}-{t.publishedTopTen?.yes}
          </p>
          <p>
            {t.reads} ({tenths(t.reads / t.readers)}/U)
          </p>
          <p>
            {Math.round(t.trust / 1000)}k ({Math.round(t.trust / t.trustees)}/U)
          </p>
          <p>{t.emailSubscriptions}</p>
          <p>{t.discerningDeliveries}</p>
          <p>{percent(t.discerningOpens / (t.discerningDeliveries || 1))}%</p>
          <p>{percent(t.discerningClickeds / (t.discerningDeliveries || 1))}%</p>
          <p>{hundredths(t.openRateDelta)}</p>
          <p>{percent(t.unsubscribes / (t.deliveries || 1))}</p>
        </React.Fragment>
      ))}
    </div>
  );
}

export default AdminTheories;
