import { createSession, usePrivileges, useProducts, useSubscriptions } from '../services/payments';
import UiContext from '../state/UiContext';
import { useContext, useEffect, useState } from 'react';
import { useActivity } from '../services/activity';
import { useMe } from '../services/users';
import SignInModal from '../components/SignIn/SignInModal';
import AuthContext from '../state/AuthContext';
import classnames from 'classnames';
import BannerBackground from '../assets/images/home.png';
import Heading from '../components/Banners/Heading';
import { useInfiniteScroll } from '../util/dom';
import { flattenPages } from '../util/query';

const Membership = () => {
  const products = useProducts().data?.data;
  const { toastError } = useContext(UiContext);
  const { completedSignup, isDelegated } = useContext(AuthContext);
  const subscriptions = useSubscriptions({ enabled: !isDelegated }).data?.data;
  const me = useMe().data?.data;
  const [chosenPrice, setChosenPrice] = useState();
  const paymentsQuery = useActivity({
    idOrUsername: me?.id,
    types: ['membership-pay'],
    enabled: !!me,
  });
  useInfiniteScroll(paymentsQuery);
  const payments = flattenPages(paymentsQuery.data?.pages);

  const privileges = usePrivileges().data?.data;

  const privilegeData = {
    'evidence-all': { text: 'See All Rebuttals' },
    trust: { text: 'Earn %s Scrolls/month (📜)', value: 'quantity' },
    comment: { text: 'Comment & Respond' },
    'theory-create': {
      singular: 'Create 1 Inquiry/month',
      text: 'Create %s Inquiries/month',
      value: 'rate',
    },
  };
  const privilegeKeys = Object.keys(privilegeData);
  useEffect(
    () =>
      chosenPrice &&
      completedSignup &&
      !isDelegated &&
      createSession({
        priceLookup: chosenPrice,
        onSuccess: data => {
          window.location.href = data?.data;
        },
        onError: data => {
          toastError(data.message || data);
          setChosenPrice(undefined);
        },
      }),
    [chosenPrice, completedSignup, isDelegated, toastError, setChosenPrice],
  );

  const activePriceIds = subscriptions?.data
    ?.filter(sub => sub.status === 'active')
    .map(sub => sub.items?.data[0].price.id);

  return (
    <div>
      <Heading text='Become a Member' backgroundImg={BannerBackground} />

      <div className='mx-4 md:mx-12'>
        <p className='mt-8 text-balance text-center'>
          Evincer membership gives you the opportunity to view all of our content, participate in
          discussions, and even create your own Inquiries.
        </p>
        <p className='mt-4 text-balance text-center'>
          Joining also contributes to the mission of providing an uncensored archive of
          controversial evidence, free from advertiser influence.
        </p>

        <div className='gap-8 grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 mt-9'>
          {products
            ?.filter(p => p.prices?.length)
            .map(product => (
              <div key={product.id} className='p-7 border rounded shadow-lg'>
                <div className='flex flex-row justify-between space-x-4'>
                  <div>
                    <p className='text-3xl'>{product.name}</p>
                    <p
                      className={classnames(
                        activePriceIds?.includes(product.prices[0].id)
                          ? 'font-bold'
                          : 'font-normal',
                        'text-nowrap',
                      )}
                    >
                      ${product.prices[0].unit_amount / 100} USD/month
                    </p>
                  </div>
                  <button
                    disabled={chosenPrice}
                    className={classnames(
                      !activePriceIds?.includes(product.prices[0].id)
                        ? 'bg-primary text-white'
                        : 'border',
                      { 'blur-sm': chosenPrice === product.prices[0].lookup_key },
                      'rounded font-bold px-3',
                    )}
                    onClick={() => setChosenPrice(product.prices[0].lookup_key)}
                  >
                    {activePriceIds?.includes(product.prices[0].id)
                      ? 'Modify'
                      : activePriceIds?.length
                      ? 'Switch'
                      : 'Join'}
                  </button>
                </div>

                <div className='mt-8'>
                  {privileges
                    ?.filter(
                      priv => priv.tier === product.metadata.tier && privilegeData[priv.privilege],
                    )
                    .sort(
                      (a, b) =>
                        privilegeKeys.indexOf(a.privilege) - privilegeKeys.indexOf(b.privilege),
                    )
                    .map(priv => {
                      const v = priv[privilegeData[priv.privilege].value];
                      const vs = v > 1000 ? v / 1000 + 'k' : v;
                      return (
                        <p className='text-nowrap text-lg py-3 border-t' key={priv.id}>
                          {(
                            (v === 1 && privilegeData[priv.privilege].singular) ||
                            privilegeData[priv.privilege].text
                          ).replace(/%s/, vs)}
                        </p>
                      );
                    })}
                </div>
              </div>
            ))}
        </div>

        {payments?.length > 0 && (
          <div className='mt-5'>
            <p className='font-bold'>Past membership rewards</p>
            {payments.map(payment => (
              <div className='grid grid-cols-2' key={payment.id}>
                <p>{payment.createdAt.substr(0, 10)}</p>
                <p className='text-right'>{payment.trustEarned} 📜</p>
              </div>
            ))}
          </div>
        )}
      </div>

      <SignInModal
        open={chosenPrice && (!completedSignup || isDelegated)}
        title={'Membership requires signing in'}
        prompt={'Please enter your email address here'}
        onClose={() => setChosenPrice(undefined)}
        context='membership'
      />
    </div>
  );
};

export default Membership;
