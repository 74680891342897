import { useGetToken } from '../services/auth';
import React, { useCallback, useContext, useState } from 'react';
import UiContext from '../state/UiContext';
import { validateEmail } from '../services/validators';
import { useSendContactMessage } from '../services/contact';
import BannerImg from '../assets/images/evidence.png';
import Heading from '../components/Banners/Heading';
import { useMe } from '../services/users';
import { useNavigate } from 'react-router-dom';
import StructuredDataOrg from '../components/StructuredData/StructuredDataOrg';

const Contact = () => {
  const createToken = useGetToken();
  const sendContactForm = useSendContactMessage();
  const { toastError, toastSuccess } = useContext(UiContext);
  const me = useMe().data?.data;
  const [name, setName] = useState(me?.displayName || '');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [validationError, setValidationError] = useState('');
  const navigate = useNavigate();

  const sendForm = useCallback(() => {
    sendContactForm.mutate(message + '\n' + name, {
      onSuccess: () => {
        toastSuccess('Message Sent');
        setName('');
        setEmail('');
        setMessage('');
      },
    });
  }, [sendContactForm, name, message, toastSuccess, setName, setEmail, setMessage]);

  const onSubmit = () => {
    if (!message) {
      toastError('No message to send');
      return;
    }
    if (me?.hasEmail) sendForm();
    else if (!email) {
      toastError('Email required');
      setValidationError('Email required');
    } else {
      const error = validateEmail(email);
      setValidationError(error);
      if (error) {
        toastError(error);
        return;
      }

      createToken.mutate(
        { email, context: 'contact' },
        {
          onSuccess: sendForm,
          onError: res => toastError(res.message),
        },
      );
    }
  };

  const loading = sendContactForm.isLoading || createToken.isLoading;

  const inputClass = 'p-1 text-unselected text-base w-full border border-slate-100';

  return (
    <div>
      <StructuredDataOrg />
      <Heading text='Contact Us' backgroundImg={BannerImg} />
      <div className={'m-4 md:m-8 flex flex-col items-center'}>
        <div className={'max-w-lg space-y-4'}>
          <input
            className={inputClass}
            name={'name'}
            value={name}
            placeholder={'Your name (optional)'}
            onChange={e => setName(e.target.value)}
          />
          {!me?.hasEmail && (
            <input
              className={inputClass}
              name={'email'}
              placeholder={'Your email'}
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          )}
          <textarea
            className={inputClass}
            name={'message'}
            maxLength={2000}
            rows={5}
            placeholder={'Your message'}
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
          {message.length > 1800 && (
            <p className='text-sm text-unselected text-right'>{message.length}/2000</p>
          )}
          <p className={'text-red-500'}>{validationError}</p>
          <div className={'flex justify-around'}>
            <button
              className={'bg-primary text-white font-bold text-base p-3 rounded'}
              onClick={onSubmit}
              disabled={loading}
            >
              {loading ? 'Processing...' : 'Submit'}
            </button>
            <button
              className={'font-bold text-base p-3 border rounded border-slate-200'}
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
          </div>
          <p>You can also reach us at support@evincer.org</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
