import { fetchClient } from 'services/api';
import { useMutation, useQueryClient } from 'react-query';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import AuthContext from '../state/AuthContext';
import { keys as evidenceKeys } from 'services/evidence';

const apiClient = fetchClient();

export const subscribe = async ({ path, dataKey, id }) => {
  const res = await apiClient.post(path, {
    [dataKey]: id,
  });

  return res.data;
};

export const unsubscribe = async ({ path, id }) => {
  const url = `${path}/${id}`;
  await apiClient.delete(url);
};

const useSubscribe = (keys, path, dataKey) => {
  const queryClient = useQueryClient();
  return useMutation(id => subscribe({ path, dataKey, id }), {
    onSuccess: () => keys.forEach(key => queryClient.invalidateQueries(key)),
  });
};

const useUnsubscribe = (keys, path) => {
  const queryClient = useQueryClient();
  return useMutation(id => unsubscribe({ path, id }), {
    onSuccess: () => keys.forEach(key => queryClient.invalidateQueries(key)),
  });
};

const invalidTheoryKeys = [['theory'], ['theories']];
export const useSubscribeTheory = () =>
  useSubscribe(invalidTheoryKeys, '/theory-subscription', 'theory');
export const useUnsubscribeTheory = () => useUnsubscribe(invalidTheoryKeys, '/theory-subscription');

const invalidEvidenceKeys = [
  evidenceKeys.allLatest(),
  evidenceKeys.allOnes(),
  evidenceKeys.allChildren(),
];
export const useSubscribeEvidence = () =>
  useSubscribe(invalidEvidenceKeys, '/evidence-subscription', 'evidence');
export const useUnsubscribeEvidence = () =>
  useUnsubscribe(invalidEvidenceKeys, '/evidence-subscription');

const useSubscription = ({ subscribe, unsubscribe, subject }) => {
  const [promptForCredentials, setPromptForCredentials] = useState(false);
  const { completedSignup } = useContext(AuthContext);
  const subscribed = useMemo(() => subject?.subscription?.isActive, [subject]);

  const toggleSubscribe = useCallback(() => {
    if (!completedSignup) {
      setPromptForCredentials(true);
    } else if (subscribed) unsubscribe.mutate(subject.id);
    else subscribe.mutate(subject.id);
  }, [completedSignup, setPromptForCredentials, subject, subscribed, unsubscribe, subscribe]);

  useEffect(() => {
    if (promptForCredentials && completedSignup) {
      setPromptForCredentials(false);
      toggleSubscribe();
    }
  }, [promptForCredentials, completedSignup, setPromptForCredentials, toggleSubscribe]);

  return {
    subscribed,
    toggleSubscribe,
    promptForCredentials,
    setPromptForCredentials,
    isLoading: subscribe.isLoading || unsubscribe.isLoading,
  };
};

export const useTheorySubscription = ({ theory }) => {
  const theorySubscribe = useSubscribeTheory();
  const theoryUnsubscribe = useUnsubscribeTheory();

  return useSubscription({
    subscribe: theorySubscribe,
    unsubscribe: theoryUnsubscribe,
    subject: theory,
  });
};

export const useEvidenceSubscription = ({ evidence }) => {
  const subscribe = useSubscribeEvidence();
  const unsubscribe = useUnsubscribeEvidence();

  return useSubscription({ subscribe, unsubscribe, subject: evidence });
};

const importSubs = async ({ id, slug }) =>
  await apiClient.patch('/theory-subscription/' + id, { slug }).then(res => res.data);

export const useImportSubs = () => {
  const queryClient = useQueryClient();
  return useMutation(importSubs, {
    onSuccess: () => queryClient.invalidateQueries('theories'),
  });
};
