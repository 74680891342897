import { useTheoryEvidence } from '../../services/evidence';
import { flattenPages } from '../../util/query';
import { evidenceLink, theoryLink, userLink } from '../../util/id';
import {
  JSONLD,
  Question,
  Answer,
  Author,
  GenericCollection,
  Generic,
} from 'react-structured-data';
import { useGetComments } from '../../services/comments';

// these don't work because react-structed-data assumes children are of its types
// const UserAuthor = ({ user }) => {
//   return (
//     <Author jsonldtype='Person' name={user.displayName || user.username} url={userLink(user)} />
//   );
// };
//
// const EvidenceAnswer = ({ evidence: e }) => {
//   return (
//     <Answer
//       text={e.title}
//       key={e.id}
//       url={evidenceLink(e)}
//       upvoteCount={e.promotes}
//       datePublished={e.publishedAt || e.activatedAt}
//     >
//       <Author
//         jsonldtype='Person'
//         name={e.user.displayName || e.user.username}
//         url={userLink(e.user)}
//       ></Author>
//     </Answer>
//   );
// };

const StructuredDataTheory = ({ theory }) => {
  const query = {
    theoryId: theory?.id,
    parentId: null,
  };
  const forEvidence = flattenPages(useTheoryEvidence({ ...query, isFor: true }).data?.pages);
  const againstEvidence = flattenPages(useTheoryEvidence({ ...query, isFor: false }).data?.pages);
  const commentsQuery = useGetComments(
    { subjectType: 'theory', subjectId: theory?.id },
    { enabled: !!theory },
  );
  const comments = flattenPages(commentsQuery.data?.pages);

  const allEvidence = forEvidence.concat(againstEvidence);
  const accepted = allEvidence.filter(e => e.status === 'published' && e.place === 1);
  const suggested = allEvidence.filter(e => e.status === 'published' && e.place !== 1);

  if (!theory || (!accepted.length && !suggested.length)) return null;

  return (
    <JSONLD>
      <Generic jsonldtype='QAPage'>
        <Question
          type='mainEntity'
          answerCount={theory.evidenceCount}
          name={theory.title}
          text={theory.body}
          keywords={theory.tags.map(t => t.title).join(',')}
          datePublished={theory.publishedAt || theory.createdAt}
          dateModified={theory.updatedAt}
          image={theory.image?.url}
          thumbnailUrl={theory.image?.thumbnail?.url}
          url={theoryLink(theory)}
        >
          <Author
            name={theory.user.displayName || '@' + theory.user.username}
            url={userLink(theory.user)}
          />

          {accepted.length && (
            <GenericCollection type={'acceptedAnswer'}>
              {accepted.map(e => (
                <Answer
                  name={e.title}
                  text={e.body}
                  citation={e.citation}
                  key={e.id}
                  url={evidenceLink(e)}
                  upvoteCount={e.promotes}
                  downvoteCount={e.demotes}
                  datePublished={e.publishedAt || e.activatedAt}
                >
                  <Author
                    name={e.user.displayName || '@' + e.user.username}
                    url={userLink(e.user)}
                  ></Author>
                </Answer>
              ))}
            </GenericCollection>
          )}
          {suggested.length && (
            <GenericCollection type={'suggestedAnswer'}>
              {suggested.map(e => (
                <Answer
                  name={e.title}
                  text={e.title}
                  citation={e.citation}
                  key={e.id}
                  url={evidenceLink(e)}
                  upvoteCount={e.promotes}
                  downvoteCount={e.demotes}
                  datePublished={e.publishedAt || e.activatedAt}
                >
                  <Author
                    name={e.user.displayName || '@' + e.user.username}
                    url={userLink(e.user)}
                  ></Author>
                </Answer>
              ))}
            </GenericCollection>
          )}

          {comments?.length && (
            <GenericCollection type={'comment'}>
              {comments.map(comment => (
                <Generic
                  key={comment.id}
                  jsonldtype='Comment'
                  schema={{ text: comment.body, datePublished: comment.createdAt }}
                >
                  <Author
                    name={comment.user.displayName || '@' + comment.user.username}
                    url={userLink(comment.user)}
                  ></Author>
                </Generic>
              ))}
            </GenericCollection>
          )}
        </Question>
      </Generic>
    </JSONLD>
  );
};

export default StructuredDataTheory;
