import UsernameModal from './UsernameModal';
import SignInModal from './SignIn/SignInModal';
import { useContext, useEffect } from 'react';
import AuthContext from '../state/AuthContext';

const CredentialModals = ({
  open,
  requireUsername,
  signinPrompt,
  usernamePrompt,
  onSuccess,
  onClose,
  context,
}) => {
  const { isNamed, isDelegated, completedSignup, isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (
      open &&
      completedSignup &&
      isAuthenticated &&
      (!requireUsername || isNamed) &&
      !isDelegated
    ) {
      onSuccess && onSuccess();
      onClose && onClose();
    }
  }, [
    open,
    isNamed,
    isDelegated,
    isAuthenticated,
    completedSignup,
    requireUsername,
    onSuccess,
    onClose,
  ]);

  return (
    <>
      <SignInModal
        open={open && (!completedSignup || !isAuthenticated || isDelegated)}
        title={signinPrompt}
        onClose={onClose}
        context={context}
      />
      <UsernameModal
        open={open && completedSignup && isAuthenticated && !isNamed && requireUsername}
        title={usernamePrompt}
        onClose={onClose}
      />
    </>
  );
};

export default CredentialModals;
