import { flattenPages } from '../../util/query';
import { evidenceLink, theoryLink, userLink } from '../../util/id';
import { JSONLD, Answer, Author, GenericCollection, Generic } from 'react-structured-data';
import { useGetComments } from '../../services/comments';

const StructuredDataEvidence = ({ evidence }) => {
  const commentsQuery = useGetComments(
    { subjectType: 'evidence', subjectId: evidence?.id },
    { enabled: !!evidence?.id },
  );
  const comments = flattenPages(commentsQuery.data?.pages);

  if (!evidence || evidence.parent) return null;

  return (
    <JSONLD>
      <Answer
        name={evidence.title}
        text={evidence.body}
        citation={evidence.citation}
        url={evidenceLink(evidence)}
        upvoteCount={evidence.promotes}
        downvoteCount={evidence.demotes}
        datePublished={evidence.publishedAt || evidence.activatedAt}
        isPartOf={theoryLink(evidence.theory)}
      >
        <Author
          name={evidence.user.displayName || '@' + evidence.user.username}
          url={userLink(evidence.user)}
        ></Author>

        {comments?.length && (
          <GenericCollection type={'comment'}>
            {comments.map(comment => (
              <Generic
                key={comment.id}
                jsonldtype='Comment'
                schema={{ text: comment.body, datePublished: comment.createdAt }}
              >
                <Author
                  name={comment.user.displayName || '@' + comment.user.username}
                  url={userLink(comment.user)}
                ></Author>
              </Generic>
            ))}
          </GenericCollection>
        )}
      </Answer>
    </JSONLD>
  );
};

export default StructuredDataEvidence;
