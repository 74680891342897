import { useContext } from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { useTagSubscription } from 'services/tag-subscription';
import { useTheories } from 'services/theories';
import { useTags } from 'services/tags';
import AuthContext from 'state/AuthContext';

import SubscriptionButton from 'components/SubscriptionButton';
import { flattenPages } from '../util/query';
import HeadTags from '../components/HeadTags';
import BannerImg from '../assets/images/inquiries.png';
import Heading from '../components/Banners/Heading';
import TheoryGrid from '../components/Cards/TheoryGrid';
import { useInfiniteScroll } from '../util/dom';

const Theories = () => {
  const params = useParams();
  const { completedSignup } = useContext(AuthContext);

  const tagSlug = params.slug;
  const enableSubscription = !!(tagSlug && completedSignup);
  const theoriesQuery = useTheories({ tagSlug });
  useInfiniteScroll(theoriesQuery);
  const tagSubscriptionHook = useTagSubscription({
    tagIdOrSlug: tagSlug,
    enabled: enableSubscription,
  });
  const tags = useTags().data?.data;
  const tagSubscription = tagSubscriptionHook.data?.data;
  const tagTitle =
    tags?.reduce((title, tag) => title || (tag.slug === tagSlug && tag.title), null) || tagSlug;
  const headline = tagTitle ? _.capitalize(tagTitle) + ' Inquiries' : 'Inquiries';

  const theories = theoriesQuery.isLoading
    ? [null, null, null, null, null, null]
    : flattenPages(theoriesQuery.data?.pages);

  return (
    <div>
      <HeadTags title='Inquiries' />

      <Heading text={headline} backgroundImg={BannerImg} />

      <div className='flex flex-col space-y-2'>
        <TheoryGrid theories={theories} className='m-4' isFetching={theoriesQuery.isFetching} />

        {!theoriesQuery.isLoading && !theories?.length && (
          <Stack direction='row' justifyContent='space-around'>
            <p className='text-xl'>There are no inquiries here yet.</p>
            <Button component={RouterLink} to={'/inquiries/new'}>
              Why not create one?
            </Button>
          </Stack>
        )}
      </div>
      {enableSubscription && tagSubscriptionHook.status === 'success' && (
        <SubscriptionButton
          subscription={tagSubscription}
          dataId={tagSlug}
          dataKey='tag'
          path='/tag-subscription'
          tooltip={'Get notified when a new #' + tagSlug + ' inquiry is published'}
        />
      )}
    </div>
  );
};

export default Theories;
