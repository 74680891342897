import classnames from 'classnames';
import ActivityList from '../components/ActivityList';
import { useState } from 'react';
import { useActivity } from '../services/activity';
import { flattenPages } from '../util/query';

const filters = {
  All: [],
  Inquiries: ['theory-activate', 'theory-publish', 'theory-unpublish', 'theory-republish'],
  Arguments: [
    'evidence-publish',
    'evidence-unpublish',
    'evidence-summarize',
    'evidence-edit',
    'evidence-republish',
    'evidence-activate',
    'confirm-authorship',
  ],
  Comments: ['comment-create', 'comment-respond'],
  Opinions: ['theory-likelihood-log'],
};
filters.All = [
  ...filters.Inquiries,
  ...filters.Arguments,
  ...filters.Comments,
  ...filters.Opinions,
];

const UserActivity = ({ user }) => {
  const [filter, setFilter] = useState('All');
  const activitiesQuery = useActivity({
    idOrUsername: user?.id,
    types: filters[filter],
    enabled: !!user?.id,
  });
  const activities = flattenPages(activitiesQuery.data?.pages);

  return (
    <div className='md:w-1/2 mx-2 md:mx-6'>
      <div className='mx-2 mt-6 flex flex-row space-x-2 overflow-scroll scrollbar-hide'>
        {(filter !== 'All' ||
          activitiesQuery.data?.pages?.length > 1 ||
          activitiesQuery.hasNextPage) &&
          Object.keys(filters).map(f => (
            <button
              onClick={() => setFilter(f)}
              className={classnames(
                'rounded-full px-3 py-2 text-sm md:text-base font-semibold cursor-pointer',
                filter === f ? 'bg-primary text-white' : 'bg-gray-100 text-gray-400 ',
              )}
              key={f}
            >
              {f}
            </button>
          ))}
      </div>

      <div className='mt-2 md:mt-4'>
        <ActivityList activities={activities} skeleton={activitiesQuery.isLoading} />
      </div>
    </div>
  );
};

export default UserActivity;
