import {
  markTheoryRead,
  useGetRevisions,
  useGetTheory,
  useUpdateLikelihood,
} from '../services/theories';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import UiContext from '../state/UiContext';
import HeadTags from '../components/HeadTags';
import { track } from '../services/analytics';
import { useJwt } from '../services/auth';
import YesNoBar from '../components/Theory/YesNoBar';
import Evidences from '../components/Evidence/Evidences';
import classnames from 'classnames';
import Tutorial from '../components/Tutorial';
import { useIsAbTest } from '../services/ab-tests';
import CommentList from '../components/Comments/CommentList';
import { useTheoryEvidence } from '../services/evidence';
import RevisionHistory from '../components/RevisionHistory';
import { sameId, theoryPath } from '../util/id';
import TheoryTitleBody from '../components/Theory/TheorySection/TheoryTitleBody';
import { useMe } from '../services/users';
import AuthContext from '../state/AuthContext';
import TheoryEditor from '../components/Theory/TheoryEditor';
import StructuredDataTheory from '../components/StructuredData/StructuredDataTheory';

const tutorialSteps = [
  {
    anchorId: 'theory-title',
    text: 'This question is the prompt for all Evidence below.',
    placement: 'bottom',
  },
  {
    anchorId: 'yes-no-bar',
    text: 'Evidence is posted into Yes or No columns where it is sorted by user vote.',
    placement: 'top',
  },
  {
    anchorId: 'evidence-columns',
    nextClass: 'evidence-title',
    text: 'Click any Evidence title to see its summary, citation, and rebuttals.',
    placement: 'top',
  },
  {
    anchorId: 'rank-panel',
    text: 'You can upvote or downvote Evidence to help sort it by strength.',
    placement: 'bottom-end',
  },
  // {
  //   anchorId: 'yes-no-bar',
  //   text: 'Click \'Add "NO/YES" Evidence\' to contribute to this Inquiry',
  //   placement: 'bottom',
  // },
  {
    anchorId: 'subscribe-button',
    text: 'Subscribe to include this Inquiry in your Evincer Weekly.',
    placement: 'bottom-start',
  },
];

const Theory = () => {
  const params = useParams();
  const slugLookup = useGetTheory(params?.slugOrId).data?.data;
  const theoryQuery = useGetTheory(slugLookup?.id); // use id so that we keep up with cache updates
  const theory = theoryQuery.data?.data;
  const [showRevisionHistory, setShowRevisionHistory] = useState(false);
  const { isXs, isSm, isMd, isLg, isXl, setAutoScroll, toastSuccess, toastError } =
    useContext(UiContext);
  const { isAdmin } = useContext(AuthContext);
  const [forExpansions, dispatchForExpansions] = useReducer(updateTree, {});
  const [againstExpansions, dispatchAgainstExpansions] = useReducer(updateTree, {});
  const [maximize, setMaximize] = useState();
  const [collapseEvents, setCollapseEvents] = useState(0); // how many times has the user pressed "collapse all"
  const [jwt] = useJwt();
  const me = useMe().data?.data;
  const [editing, setEditing] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const updateLikelihood = useUpdateLikelihood();

  useEffect(() => {
    if (searchParams.get('likelihood') && theory?.id && !updateLikelihood.isLoading) {
      updateLikelihood.mutate(
        { theoryId: theory.id, likelihood: +searchParams.get('likelihood') },
        {
          onSuccess: () => toastSuccess('Your opinion has been recorded'),
          onError: data => toastError(data?.message),
        },
      );
      searchParams.delete('likelihood');
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, setSearchParams, theory, updateLikelihood, toastSuccess, toastError]);

  useEffect(
    () =>
      theory &&
      location.pathname !== theoryPath(theory) &&
      navigate(theoryPath(theory), { replace: true }),
    [theory, navigate, location],
  );

  const where = {
    theoryId: theory?.id,
    parentId: null,
  };
  const forEvidencesQuery = useTheoryEvidence({ ...where, isFor: true });
  const againstEvidencesQuery = useTheoryEvidence({ ...where, isFor: false });
  const bothHaveMore = useMemo(
    () => forEvidencesQuery.hasNextPage && againstEvidencesQuery.hasNextPage,
    [forEvidencesQuery, againstEvidencesQuery],
  );
  const onShowMore = useCallback(() => {
    forEvidencesQuery.fetchNextPage();
    againstEvidencesQuery.fetchNextPage();
  }, [forEvidencesQuery, againstEvidencesQuery]);

  const testTutorials = useIsAbTest('tutorial-rewrite');

  useEffect(() => {
    if (jwt && theory?.id) {
      // wait until jwt is set to mark read
      markTheoryRead(theory.id);
    }
  }, [jwt, theory]);

  const threshold = (isXs && 1) || (isSm && 1) || (isMd && 1) || (isLg && 3) || (isXl && 4) || 5;

  // update our representation of which evidences/editors are expanded and at what level
  // Right now this is just to set 'maximize' and is overkill but could be useful for other features.
  function updateTree(prevTree, newBranch) {
    if (newBranch === {}) return {};

    for (const b of Object.keys(newBranch)) {
      if (!prevTree[b]) prevTree[b] = newBranch[b];
      if (newBranch[b].level === 0) delete prevTree[b];
      else if (b !== 'level') updateTree(prevTree[b], newBranch[b]);
    }
    return { ...prevTree };
  }

  // has any child met our threshold for maximizing the width of that side?
  function hasMetThreshold(tree, bias = 0) {
    if (tree.level !== undefined && tree.level + bias >= threshold) return true;
    else {
      for (const b of Object.keys(tree))
        if (b !== 'level' && hasMetThreshold(tree[b], b === 'editor' ? 5 : 0))
          // editor adds 2 to help meet threshold
          return true;
    }
    return false;
  }

  // see if anything has expanded past our threshold for expansion
  useEffect(() => {
    if (hasMetThreshold(forExpansions) && maximize !== 'for') {
      setMaximize('for');
      setAutoScroll(true);
      track('maximize-column', { for: true, inquiry: theory });
    }
    if (hasMetThreshold(againstExpansions) && maximize !== 'against') {
      setMaximize('against');
      setAutoScroll(true);
      track('maximize-column', { for: false, inquiry: theory });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forExpansions, againstExpansions, threshold]);

  const onToggleYes = () => {
    if (maximize) setCollapseEvents(collapseEvents + 1);
    setMaximize(m => (m ? undefined : 'for'));
  };
  const onToggleNo = () => {
    if (maximize) setCollapseEvents(collapseEvents + 1);
    setMaximize(m => (m ? undefined : 'against'));
  };

  const canEdit = isAdmin || (sameId(me, theory?.user) && theory?.status !== 'published');

  return (
    <div>
      <HeadTags title={theory?.title} description={theory?.body} image={theory?.image?.url} />

      <StructuredDataTheory theory={theory} />

      <div className='flex flex-col mb-5'>
        <div>
          {!editing ? (
            <TheoryTitleBody
              theory={theory}
              onClickEdit={canEdit && (() => setEditing(true))}
              onClickEdited={() => {
                setShowRevisionHistory(true);
                searchParams.set('showRevisionHistory', 'true');
                setSearchParams(searchParams, { replace: true });
              }}
            />
          ) : (
            <TheoryEditor
              initialBody={editing.body}
              initialTitle={editing.title}
              theory={theory}
              onClose={() => setEditing(false)}
            />
          )}
        </div>

        {showRevisionHistory && !editing && (
          <div className='border rounded mt-5 lg:mt-6 mx-2 md:mx-4 lg:mx-6'>
            <RevisionHistory
              subject={theory}
              useQuery={useGetRevisions}
              onRestore={
                canEdit && (revision => setEditing({ title: revision.title, body: revision.body }))
              }
            />
          </div>
        )}

        <YesNoBar
          theory={theory}
          maximize={maximize ? { for: 'yes', against: 'no' }[maximize] : ''}
          collapseEvents={collapseEvents}
          onToggleYes={onToggleYes}
          onToggleNo={onToggleNo}
          onNoEditor={dispatchAgainstExpansions}
          onYesEditor={dispatchForExpansions}
        />

        <div className='px-2 md:px-3 '>
          {theory && (
            <div id='evidence-columns' className='relative flex flex-row mt-2 md:mt-3'>
              <div
                className={classnames(
                  'duration-500',
                  maximize === 'against'
                    ? 'w-full px-2'
                    : maximize === 'for'
                    ? 'w-0 h-0 overflow-hidden'
                    : 'w-1/2 pr-1 md:pr-2 ',
                )}
              >
                <Evidences
                  isFor={false}
                  theory={theory}
                  onExpand={dispatchAgainstExpansions}
                  level={1}
                  collapseEvents={collapseEvents}
                  hideShowMore={bothHaveMore}
                />
              </div>

              <div
                className={classnames(
                  'duration-500',
                  maximize === 'for'
                    ? 'w-full px-2'
                    : maximize === 'against'
                    ? 'w-0 h-0 overflow-hidden'
                    : 'w-1/2 pl-1 md:pl-2',
                )}
              >
                <Evidences
                  isFor={true}
                  theory={theory}
                  onExpand={dispatchForExpansions}
                  level={1}
                  collapseEvents={collapseEvents}
                  hideShowMore={bothHaveMore}
                />
              </div>

              {bothHaveMore && !forEvidencesQuery.isFetching && !againstEvidencesQuery.isFetching && (
                <>
                  <div className='h-8' />
                  <div className='absolute bottom-0 left-0 right-0 bg-gradient-to-t from-white flex justify-center px-6 pt-20 pointer-events-none'>
                    <button
                      className='flex rounded-lg py-5 px-9 bg-white border border-teal-600 text-teal-600 text-sm font-bold pointer-events-auto'
                      onClick={onShowMore}
                    >
                      <span>Show More</span>
                    </button>
                  </div>
                </>
              )}
            </div>
          )}

          <p className='text-base lg:text-xl mt-16 md:mt-20 mb-2'>Inquiry Comments</p>
          <CommentList subjectType='theory' subject={theory} infiniteScroll={true} />
        </div>
      </div>

      {testTutorials && <Tutorial name='inquiry-page' steps={tutorialSteps} />}
    </div>
  );
};

export default Theory;
