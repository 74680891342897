import { JSONLD, Generic } from 'react-structured-data';

const StructuredDataTheory = () => {
  return (
    <JSONLD>
      <Generic
        jsonldtype='ResearchOrganization'
        schema={{
          description:
            'A platform for sharing the best empirical evidence for controversial questions without suppressing either side',
          foundingDate: '2022-04-02',
          legalName: 'Evincer LLC',
          logo: 'https://evincer.org/logo512.png',
          name: 'Evincer',
          url: 'https://evincer.org',
          sameAs: ['x.com/Evincer_org', 'https://www.linkedin.com/company/evincer-org'],
        }}
      >
        <Generic
          type='contactPoint'
          jsonldtype='ContactPoint'
          schema={{ url: 'https://evincer.org/contact' }}
        />
      </Generic>
    </JSONLD>
  );
};

export default StructuredDataTheory;
